import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ImageUploading from "react-images-uploading";
import AddIcon from '@mui/icons-material/Add';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { formatCurrency, formatNumber, unformat } from '../../utils/utils';
import api,{ URL_API } from '../../utils/api';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CloseIcon from '@mui/icons-material/Close';
import validator from 'validator';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import CreatableSelect from 'react-select/creatable';

export default function NuevoVehiculo({ marcas, transmisiones, motores, colores, tipos_vehiculos, notification, reload, vehiculo_id }) {
  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const [anio, setAnio] = useState('');
  const handleChangeAnio = (event) => {
    setAnio(event.target.value);
  };

  const [modelo, setModelo] = useState('');
  const handleChangeModelo = (event) => {
    setModelo(event.target.value);
  };

  const [edicion, setEdicion] = useState('');
  const handleChangeEdicion = (event) => {
    setEdicion(event.target.value);
  };

  const [transmision, setTransmision] = useState('');
  const handleChangeTransmision = (event) => {
    setTransmision(event.target.value);
  };

  const [motor, setMotor] = useState('');
  const handleChangeMotor = (event) => {
    setMotor(event.target.value);
  };

  const [marca, setMarca] = useState('');
  const [marcaObj, setMarcaObj] = useState('');
  const handleChangeMarca = async (event, payload) => {
    if(event && event.__isNew__) {
      const formData = new FormData();
      formData.append('nombre', event.label);
      const res = await api.post('marcas/guardar', formData);
      if(res.data){
          event = res.data;
          notification.fire({ 
            position: 'top-end',
            icon: 'success',
            title: '¡Marca creada con éxito!',
            showConfirmButton: false,
            timer: 1500
          });
      } else {
          notification.fire({ 
            position: 'top-end',
            icon: 'error',
            title: '¡Error al crear la marca!',
            showConfirmButton: false,
            timer: 1500
          });
      }
    }
    setMarcaObj(event);
    setMarca(event ? event.id: null);
  };


  const [color, setColor] = useState('');
  const handleChangeColor = (event) => {
    setColor(event.target.value);
  };

  const [precio, setPrecio] = useState('');
  const handleChangePrecio = (event) => {
    setPrecio(event.target.value);
  };

  const [kilometraje, setKilometraje] = useState('');
  const handleChangeKilometraje = (event) => {
    setKilometraje(event.target.value);
  };
	
  const [tipoVehiculo, setTipoVehiculo] = useState('');
  const handleChangeTipoVehiculo = (event) => {
    setTipoVehiculo(event.target.value);
  };
  
  const [enganche, setEnganche] = useState('');
  const handleChangeEnganche = (event) => {
    setEnganche(event.target.value);
  };

  const [mensualidad, setMensualidad] = useState('');
  const handleChangeMensualidad = (event) => {
    setMensualidad(event.target.value);
  };

  const [plazos, setPlazos] = useState('');
  const handleChangePlazos = (event) => {
    setPlazos(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 
  const validar = (data) => {
    if(validator.isEmpty(data.modelo + ''))
      return { valido: false, mensaje: '¡El campo Anio es requerido!'};
    if(validator.isEmpty(data.nombre + ''))
      return { valido: false, mensaje: '¡El campo Nombre es requerido!'};
    // if(validator.isEmpty(data.edicion + ''))
    //   return { valido: false, mensaje: '¡El campo Edicion es requerido!'};
    if(validator.isEmpty(data.transmision + ''))
      return { valido: false, mensaje: '¡El campo Transmision es requerido!'};
    if(validator.isEmpty(data.motor + ''))
      return { valido: false, mensaje: '¡El campo Motor es requerido!'};
    if(validator.isEmpty(data.marca + ''))
      return { valido: false, mensaje: '¡El campo Marca es requerido!'};
    if(validator.isEmpty(data.color + ''))
      return { valido: false, mensaje: '¡El campo Color es requerido!'};
    if(validator.isEmpty(data.precio + ''))
      return { valido: false, mensaje: '¡El campo Precio es requerido!'};
    if(validator.isEmpty(data.kilometraje + ''))
      return { valido: false, mensaje: '¡El campo Kilometraje es requerido!'};
		if(validator.isEmpty(data.tipo_vehiculo + ''))
      return { valido: false, mensaje: '¡El campo Tipo de vehículo es requerido!'};
    if(validator.isEmpty(data.enganche + ''))
      return { valido: false, mensaje: '¡El campo Enganche es requerido!'};
    if(validator.isEmpty(data.mensualidad + ''))
      return { valido: false, mensaje: '¡El campo Mensualidad es requerido!'};
    if(validator.isEmpty(data.plazos + ''))
      return { valido: false, mensaje: '¡El campo Plazos es requerido!'};
    if(data.images.length === 0 )
      return { valido: false, mensaje: '¡Las imagenes son requeridas!'};
    return { valido: true };
  };

  const handleClickEditar = async () => {
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      const imagen = images[i];
      if(imagen.id){
        formData.append('images_data[]', imagen.id);
      } else {
        formData.append('images[]', imagen.file);
			}
    }
    formData.append('anio', anio);
    formData.append('modelo', modelo);
    formData.append('edicion', edicion);
    formData.append('transmision', transmision);
    formData.append('motor', motor);
    formData.append('marca', marca);
    formData.append('color', color);
    formData.append('precio', precio);
    formData.append('kilometraje', kilometraje);
    formData.append('tipo_vehiculo', tipoVehiculo);
    formData.append('enganche', enganche);
    formData.append('mensualidad', mensualidad);
    formData.append('plazos', plazos);

    const validacion = validar({ anio, modelo, edicion, transmision, motor, marca, color, precio, kilometraje, enganche, mensualidad, plazos, images, tipo_vehiculo: tipoVehiculo }); 
    if(validacion.valido) {
      const res = await api.post('vehiculos/editar/' + vehiculo_id, formData);
      setOpen(false);
      if(res.data){
          setOpen(false);
          notification.fire({ 
            position: 'top-end',
            icon: 'success',
            title: '¡Vehículo editar con éxito!',
            showConfirmButton: false,
            timer: 1500
          });
          reload();
      } else {
          notification.fire({ 
            position: 'top-end',
            icon: 'error',
            title: '¡Error al editar el vehículo!',
            showConfirmButton: false,
            timer: 1500
          });
      }
    } else {
      notification.fire({ 
        position: 'top-end',
        icon: 'error',
        title: validacion.mensaje,
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  const getVehiculo = async () => {
    const res = await api.get('vehiculos/vehiculo_id/'+vehiculo_id);
    setAnio(res.data.anio);
    setModelo(res.data.modelo);
    setEdicion(res.data.edicion);
    setTransmision(res.data.transmision_id);
    setMotor(res.data.motor_id);
    setMarca(res.data.marca_id);
    setMarcaObj({
      id: res.data.marca_id,
      codigo: res.data.marca_codigo,
      nombre: res.data.marca_nombre,
    });
    setColor(res.data.color_id);
    setPrecio(res.data.precio);
    setKilometraje(res.data.kilometraje);
    setTipoVehiculo(res.data.tipo_vehiculo_id);
    setEnganche(res.data.enganche);
    setMensualidad(res.data.mensualidad);
    setPlazos(res.data.plazos);
    setImages(res.data.imagenes.map(  img => ({ id: img.id, data_url: URL_API + img.src })))
  }

  useEffect(() => {
		if(open)
			getVehiculo();
  },[open]);



  return (
      <>
        <div className="favorite-wrap srp" data-v-185805e8="" data-v-96f9fdd4="" style={{right: '3em'}}  onClick={handleOpen}>
            <button type="button" className="favorite" data-v-185805e8="">
                <EditIcon style={{ color: '#941b1c'}} />
            </button>
        </div>
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box className='css-e8drjz'>
                <button aria-label="Close dialog" className="sc-bkkeKt UpVmF sc-fTQvRK getUpR" onClick={handleClose}>
                    <CloseIcon />
                </button>
                <div className="sc-fIITEw bJcdFY" style={{width: '100%'}}>
                    <div className="sc-cRKLkh enDpjW" style={{width: '100%'}}>
                        <h2 className="sc-gsDKAQ sc-hUplSX gsoPfm jwXivr">Editar vehículo</h2>
                        <div action="#" className="sc-lheXJl kRdoR">
                            <div className="sc-cAhXWc keUCuc">
                                <div className="sc-giYglK ktrmkx sc-hmvnCu idayyE">
                                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Marca <span style={{color: 'red'}}>*</span></span></label></div>
                                    <div className="sc-ikJyIC iZiwmQ">
                                      <CreatableSelect 
                                          classNames={{
                                            control: (state) => 'sc-jObWnj bdShXQ',
                                          }}
                                          getOptionLabel={(e) =>e.nombre || e.label}
                                          getOptionValue={(e) =>e.id}
                                          formatCreateLabel={(inputValue) => 'Crear la marca "'+ inputValue + '"'}
                                          isClearable
                                          value={marcaObj}
                                          noOptionsMessage={() => 'Sin marcas registradas.'}
                                          placeholder="Registrar o seleccionar"
                                          onChange={handleChangeMarca}
                                          options={marcas} 
                                        />
                                    </div>
                                </div>
                                <div className="sc-gWXbKe byPIxE sc-gsFzgR fXipfN">
                                    <div className="sc-egiyK bCRnWf">
                                        <label id="state-selector" for="state-selector">
                                            <span className="sc-furwcr sc-bqiRlB dJRzsx">Color <span style={{color: 'red'}}>*</span></span>
                                        </label>
                                    </div>
                                    <div className="sc-ikJyIC iZiwmQ">
                                        <select className="sc-jObWnj bdShXQ" value={color} onChange={handleChangeColor} >
                                            <option disabled selected value="">Seleccionar una opción</option>
                                            { colores.map((color,key) => (
                                                <option key={key} value={color.id}>{color.nombre}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-giYglK ktrmkx sc-leSONj eoOSap">
                                <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Modelo <span style={{color: 'red'}}>*</span></span></label></div>
                                <div className="sc-ikJyIC iZiwmQ"><input  placeholder="Modelo" name="last" className="sc-jJoQJp jjlIvY" value={modelo} onChange={handleChangeModelo}  /></div>
                            </div>
                            <div className="sc-cAhXWc keUCuc">
                                <div className="sc-giYglK ktrmkx sc-hmvnCu idayyE">
                                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Edición </span></label></div>
                                    <div className="sc-ikJyIC iZiwmQ"><input placeholder="Edición" name="phone" className="sc-jJoQJp jjlIvY" value={edicion} onChange={handleChangeEdicion}  /></div>
                                </div>
                                <div className="sc-gWXbKe byPIxE sc-gsFzgR fXipfN">
                                    <div className="sc-egiyK bCRnWf">
                                        <label id="state-selector" for="state-selector">
                                            <span className="sc-furwcr sc-bqiRlB dJRzsx">Año <span style={{color: 'red'}}>*</span></span>
                                        </label>
                                    </div>
																		<div className="sc-ikJyIC iZiwmQ">
                                        <select className="sc-jObWnj bdShXQ" value={anio} onChange={handleChangeAnio} >
                                            <option disabled selected value="">Seleccionar una opción</option>
                                            { [...Array(Number(moment().format('YYYY'))-2000+1).keys()].map(x => x + 2000).sort((a,b) => b-a).map((transmision) => (
                                                <option key={transmision} value={transmision}>{transmision}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-cAhXWc keUCuc">
                                <div className="sc-giYglK ktrmkx sc-hmvnCu idayyE">
                                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Transmisión <span style={{color: 'red'}}>*</span></span></label></div>
                                    <div className="sc-ikJyIC iZiwmQ">
                                        <select className="sc-jObWnj bdShXQ" value={transmision} onChange={handleChangeTransmision} >
                                            <option disabled selected value="">Seleccionar una opción</option>
                                            { transmisiones.map((transmision,key) => (
                                                <option key={key} value={transmision.id}>{transmision.nombre}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="sc-gWXbKe byPIxE sc-gsFzgR fXipfN">
                                    <div className="sc-egiyK bCRnWf">
                                        <label id="state-selector" for="state-selector">
                                            <span className="sc-furwcr sc-bqiRlB dJRzsx">Motor <span style={{color: 'red'}}>*</span></span>
                                        </label>
                                    </div>
                                    <div className="sc-ikJyIC iZiwmQ">
                                        <select className="sc-jObWnj bdShXQ" value={motor} onChange={handleChangeMotor} >
                                            <option disabled selected value="">Seleccionar una opción</option>
                                            { motores.map((motor,key) => (
                                                <option key={key} value={motor.id}>{motor.nombre}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
														<div className="sc-cAhXWc keUCuc">
                                <div className="sc-giYglK ktrmkx sc-hmvnCu idayyE">
                                    <div className="sc-egiyK bCRnWf">
                                        <label id="state-selector" for="state-selector">
                                            <span className="sc-furwcr sc-bqiRlB dJRzsx">Tipo de vehículo <span style={{color: 'red'}}>*</span></span>
                                        </label>
                                    </div>
                                    <div className="sc-ikJyIC iZiwmQ">
                                        <select className="sc-jObWnj bdShXQ" value={tipoVehiculo} onChange={handleChangeTipoVehiculo} >
                                            <option disabled selected value="">Seleccionar una opción</option>
                                            { tipos_vehiculos.map((tv,key) => (
                                                <option key={key} value={tv.id}>{tv.nombre}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="sc-gWXbKe byPIxE sc-gsFzgR fXipfN">
                                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Kilometraje <span style={{color: 'red'}}>*</span></span></label></div>
                                    <div className="sc-ikJyIC iZiwmQ"><input type="number" placeholder="Kilometraje" name="addressOne" className="sc-jJoQJp jjlIvY" value={kilometraje} onChange={handleChangeKilometraje}  /></div>
                                </div>
                            </div>
                            <div className="sc-cAhXWc keUCuc">
                                <div className="sc-giYglK ktrmkx sc-hmvnCu idayyE">
                                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Precio <span style={{color: 'red'}}>*</span></span></label></div>
                                    <div className="sc-ikJyIC iZiwmQ"><input type="number" placeholder="Precio" name="addressTwo" className="sc-jJoQJp jjlIvY" value={precio} onChange={handleChangePrecio}  /></div>
                                </div>
                                <div className="sc-gWXbKe byPIxE sc-gsFzgR fXipfN">
                                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Enganche <span style={{color: 'red'}}>*</span></span></label></div>
                                    <div className="sc-ikJyIC iZiwmQ"><input type="number" placeholder="Enganche" name="income" className="sc-jJoQJp jjlIvY" value={enganche} onChange={handleChangeEnganche} /></div>
                                </div>
                            </div>
                            <div className="sc-cAhXWc keUCuc">
                                <div className="sc-giYglK ktrmkx sc-hmvnCu idayyE">
                                   <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Plazos <span style={{color: 'red'}}>*</span></span></label></div>
                                    <div className="sc-ikJyIC iZiwmQ"><input type="number" placeholder="Plazos" name="income" className="sc-jJoQJp jjlIvY" value={plazos} onChange={handleChangePlazos} /></div>
                                </div>
                                <div className="sc-gWXbKe byPIxE sc-gsFzgR fXipfN">
                                    <div className="sc-egiyK bCRnWf"><label id="state-selector" for="state-selector"><span className="sc-furwcr sc-bqiRlB dJRzsx">Mensualidad <span style={{color: 'red'}}>*</span></span></label></div>
                                    <div className="sc-ikJyIC iZiwmQ"><input type="number" placeholder="Mensualidad" name="income" className="sc-jJoQJp jjlIvY" value={mensualidad} onChange={handleChangeMensualidad} /></div>
                                </div>
                            </div>
                            <ImageUploading
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                                acceptType={["jpg","png","jpeg"]}
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemove,
                                    isDragging,
                                    dragProps, 
                                    errors
                                }) => (
                                    <Fragment>
                                        <ImageList sx={{ height: 250, width: '100%' }} cols={imageList.length > 0 ? 3 : 1 }>
                                            <ImageListItem  {...dragProps} style={{ position: 'relative', cursor: 'pointer' ,border: isDragging ? 'dashed 1px red': 'dashed 1px black'}} onClick={onImageUpload}>
                                                <div style={{ position: 'absolute', top: '50%', left: '50%', fontSize: '10px', transform: 'translate(-50%, -50%)', textAlign: 'center'}}>
                                                    <AddAPhotoIcon style={{ fontSize: '4em'}} /> 
                                                    <br />
                                                    {isDragging ? "Suelte la imagen aquí porfavor" : "Click aquí o suelte la imagen aquí"}
                                                </div>
                                            </ImageListItem>
                                            {imageList.map((item, index) => (
                                                <ImageListItem 
                                                    key={item.img}
                                                >
                                                    <img
                                                        src={`${item.data_url}`}
                                                        srcSet={`${item.data_url}`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                    <ImageListItemBar
                                                        sx={{
                                                            background:
                                                            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, ' +
                                                            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                        }}
                                                        title={item.title}
                                                        actionIcon={
                                                            <Fragment>
                                                                <IconButton
                                                                    sx={{ color: 'white' }}
                                                                    aria-label={`star ${item.title}`}
                                                                    onClick={() => onImageRemove(index)}
                                                                >
                                                                    <DeleteOutlineIcon style={{ color: 'white' }}/>
                                                                </IconButton>
                                                            </Fragment>
                                                        }
                                                        actionPosition="left"
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                        {errors && <div>
                                            {errors.maxNumber && <span>Number of selected images exceed maxNumber</span>}
                                            {errors.acceptType && <span>Your selected file type is not allow</span>}
                                            {errors.maxFileSize && <span>Selected file size exceed maxFileSize</span>}
                                            {errors.resolution && <span>Selected file is not match your desired resolution</span>}
                                        </div>}
                                    </Fragment>
                                )}
                            </ImageUploading>
                            <div className="sc-gqtqkP foVdkY">
                            </div>
                            <button className="sc-hBUSln bFdfPe sc-hoHwyw PmcJM" onClick={handleClickEditar}>Guardar</button>
                            <span className="sc-furwcr sc-kLnunm dJRzsx ldKfSs"></span>
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
      </>
  );
}