import React, { useState, useEffect } from 'react';
import './Vehiculo.css';
import CardVehiculo from '../Vehiculos/CardVehiculo';
import { useParams } from 'react-router-dom';
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import { formatCurrency, formatNumber } from '../../utils/utils';
import api,{ URL_API } from '../../utils/api';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link } from 'react-router-dom';

export default function Vehiculo() {
  const params = useParams();
  const [car,setCar] = useState({ imagenes: [] }); 
  const [loading,setLoading] = useState(true);
  const [cars,setCars] = useState([]);

  
  useEffect(() => {
    async function getCars() {
      setLoading(true);
      const response = await api.get('vehiculos/latest');
      setCars([
          ...response.data.map(x => ({
              ...x, 
              imagenes: [
                  ...x.imagenes.map(img => ({
                    src: URL_API + img.src, 
                    titulo: x.anio +' '+ x.marca_nombre +' '+ x.modelo, 
                    tipo: '' 
                  }))
                ]
          }))
      ])
      setLoading(false);
    }
    async function getCar() { 
        window.scrollTo(0, 0);
        const response = await api.get('vehiculos/vehiculo/' + params.name);
        setCar({
          ...response.data,
          imagenes: [
            ...response.data.imagenes.map(img => ({
              src: URL_API + img.src, 
              titulo: response.data.anio +' '+ response.data.marca_nombre +' '+ response.data.modelo, 
              tipo: '' 
            }))
          ]
        })
    }
    getCar();
    getCars();
  },[params.name]);

  return (
      <div data-v-3c4b021b="" data-v-27dbbde3="" className="page-content" id="main">
        <div data-v-390c1c04="" data-v-3c4b021b="" className="wrap">
          <div data-v-390c1c04="" className="g-container content">
            <nav data-v-72dfc844="" data-v-390c1c04="" aria-label="Breadcrumb">
              <ul data-v-72dfc844="" className="list">
                <li data-v-72dfc844="" className="item">
                  <span data-v-72dfc844="" aria-hidden="true" className="separator">/</span> 
                  <Link data-v-72dfc844="" to="/cars" className="link">
                    Todos los vehículos
                  </Link>
                </li>
                <li data-v-72dfc844="" className="item">
                  <span data-v-72dfc844="" aria-hidden="true" className="separator">/</span> 
                  <Link data-v-72dfc844="" to={`./cars?marca=${car.marca_codigo}`} className="link">
                    {car.marca_nombre}
                  </Link>
                </li>
                <li data-v-72dfc844="" className="item">
                  <span data-v-72dfc844="" aria-hidden="true" className="separator">/</span> 
                  <Link data-v-72dfc844="" to={`./cars?marca=${car.marca_codigo}&&modelo=${car.modelo}`} className="link">
                    {car.marca_nombre} {car.modelo}
                  </Link>
                </li>
                <li data-v-72dfc844="" className="item">
                  <span data-v-72dfc844="" aria-hidden="true" className="separator">/</span> 
                  <Link data-v-72dfc844="" to={`/cars?marca=${car.marca_codigo}&&modelo=${car.modelo}&&anio=${car.anio}-${car.anio}`} className="link">
                    {car.anio} {car.marca_nombre} {car.modelo}
                  </Link>
                </li>
              </ul>
            </nav> 
            <a data-v-390c1c04="" href="tel:6675445969" className="link">
              <span data-v-390c1c04="" className="link-text">(667) 544 5969</span>
            </a>
          </div>
        </div>
        <div data-v-056fe423="" data-v-3c4b021b="" className="g-container">
          <div data-v-056fe423="">
            <div data-v-056fe423="" className="wrap">
              <div data-v-056fe423="" className="gallery-wrap">
                {/* <div data-v-185805e8="" data-v-056fe423="" className="favorite-wrap vdp">
                  <button data-v-185805e8="" type="button" className="favorite">
                    <FavoriteBorderIcon title="Save" style={{ color: '#941b1c'}} />
                  </button> 
                </div>  */}
                {/* <button data-v-056fe423="" type="button" className="view-btn">
                  View Interior
                </button> */}
                <Swiper 
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                  }}
                  navigation={true} 
                  modules={[Autoplay,Navigation,Pagination]} 
                  pagination={{ clickable: true }}
                  > 
                  { car.imagenes.map((image,i) => (
                    <SwiperSlide key={i}>
                      <div className="img-wrap img-wrap--main" data-v-056fe423="">
                        <picture data-v-056fe423="">
                          <img alt={image.titulo} data-sizes="auto" className="swiper-lazy img swiper-lazy-loaded" data-v-056fe423="" sizes="333px" src={image.src} />
                        </picture>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div data-v-056fe423="" className="thumbnails-wrap">
                {/* <div data-v-056fe423="" className="img-wrap thumbnails-item"><img data-v-056fe423="" src="https://images.carstory.com/9153937045387966522/1/t/920x-" alt="" className="img" /> <span data-v-056fe423="">exterior (7)</span></div>
                <div data-v-056fe423="" className="img-wrap thumbnails-item"><img data-v-056fe423="" src="https://images.carstory.com/9153937045387966522/2/t/920x-" alt="" className="img" /> <span data-v-056fe423="">interior (5)</span></div>
                <div data-v-056fe423="" className="img-wrap thumbnails-item thumbnail-text"><span data-v-056fe423="">See all
                    photos</span></div> */}
              </div>
            </div>
          </div>
        </div>
        <div data-v-0a2dfc35="" data-v-3c4b021b="" className="wrap is-sticky">
          <div data-v-0a2dfc35="" className="g-container">
            <div data-v-0a2dfc35="" id="box-detail-main-info" className="box">
              <div data-v-0a2dfc35="" className="left">
                <h1 data-v-0a2dfc35="" className="h1"><span data-v-0a2dfc35="">{car.anio} {car.marca_nombre} {car.modelo} </span></h1>
                <span data-v-0a2dfc35="">{car.edicion} / {formatNumber(car.kilometraje,0)} kilometros</span>
              </div>
              <div data-v-0a2dfc35="" className="price-wrap">
                <div data-v-0a2dfc35="" className="price">
                  <span data-v-0a2dfc35="" className="u-pos-r">
                    {formatCurrency(car.precio,0)}
                  </span> 
                </div>
              </div>
              <div data-v-0a2dfc35="" className="buttons-wrap">
                <a style={{position: 'relative', paddingLeft: '45px'}} data-v-0a2dfc35="" href={`https://api.whatsapp.com/send?phone=526675445969&text=Informacion+sobre+el+vehículo:+${car.anio}+${car.marca_nombre}+${car.modelo}+${car.edicion}+${formatNumber(car.kilometraje,0)}+kilómetros+${formatCurrency(car.precio,0)}`} target="_blank" rel="noopener noreferrer"  className="g-button g-button--secondary get-started">
                  <WhatsAppIcon style={{    position: 'absolute', left: '15px', top: '13px' }}></WhatsAppIcon>
                  CONTACTAR
                </a>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <div data-v-7a93b345="" data-v-3c4b021b="" className="vehicle-details">
          <div data-v-7a93b345="" className="g-container">
            <h2 data-v-7a93b345="" id="details-tabs" className="g-heading4">
              Detalles del vehículo
            </h2>
            <div data-v-7a93b345="" role="tablist" aria-labelledby="details-tabs" className="g-tabs tabs">
              <button data-v-7a93b345="" id="tab-1" type="button" role="tab" aria-selected="true" aria-controls="features" className="g-tabs-item is-active">
                Detalles
              </button> 
            </div>
            <div data-v-7a93b345="" id="details" role="tabpanel" aria-labelledby="tab-2" tabindex="0">
              <h3 data-v-7a93b345="" className="h3">
                Basicas
              </h3>
              <div data-v-7a93b345="" className="details-table">
                <div data-v-7a93b345="" className="details-row">
                  <span data-v-7a93b345="" className="details-property">Color</span> 
                  <span data-v-7a93b345="" className={"details-value details-value--color g-color--"+car.color_codigo}>{car.color_nombre ? car.color_nombre: 'No asignado'}</span>
                </div>
                <div data-v-7a93b345="" className="details-row">
                  <span data-v-7a93b345="" className="details-property">Tipo de vehiculo</span>
                  <span data-v-7a93b345="" className="details-value">{car.tipo_vehiculo_nombre ? car.tipo_vehiculo_nombre: 'No asignado'}</span>
                </div>
              </div>
              <h3 data-v-7a93b345="" className="h3">
                Rendimiento
              </h3>
              <div data-v-7a93b345="" className="details-table">
                <div data-v-7a93b345="" className="details-row">
                  <span data-v-7a93b345="" className="details-property">Motor</span>
                  <span data-v-7a93b345="" className="details-value">{car.motor_nombre ? car.motor_nombre: 'No asignado'}</span>
                </div>
                <div data-v-7a93b345="" className="details-row">
                  <span data-v-7a93b345="" className="details-property">Transmisión</span> 
                  <span data-v-7a93b345="" className="details-value">{car.transmision_nombre ? car.transmision_nombre: 'No asignado'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-v-9f4e18d2="" data-v-3c4b021b="" id="vdp-similar-listings" className="similars compare-enabled">
          <div data-v-9f4e18d2="" className="g-container">
              <h2 data-v-9f4e18d2="" id="similars-title" className="sc-gsDKAQ sc-jRyozQ gsoPfm kHZKIa">
                  Agregados recientemente
              </h2>
              { loading && ( <div data-v-9f4e18d2="" className="g-loader" ><span data-v-9f4e18d2="" className="u-sr">loading ...</span></div> )}
              <div data-v-9f4e18d2="" aria-labelledby="tab-recommended" role="tabpanel" className="similars-wrap" >
                  { cars.map((car,i)=> <CardVehiculo key={i} car={car} />) }
              </div>
          </div>
      </div>
    </div>
  );
}