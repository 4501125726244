import React, { useState } from 'react';
import './Login.css';
import api,{ URL_API } from '../../utils/api';
import { useNavigate  } from "react-router-dom";

    
export default function Login({ handleToken, notification }) {
  let navigate = useNavigate();
  const [usuario,setUsuario] = useState('');
  const [contrasenia,setContrasenia] = useState('');
  const [loading,setLoading] = useState(true);
  
  async function login() {
    setLoading(true);
    try {
      const response = await api.post('login',{
        usuario,
        contrasenia
      });
      if(response.data && response.data.token){
        handleToken(response.data.token);
        navigate("/dashboard");
      } else {
        notification.fire({ 
          position: 'top-end',
          icon: 'error',
          title: '¡Usuario o contraseña incorrectos!',
          showConfirmButton: false,
          timer: 1500
        });
      }
    } catch (e) {
      notification.fire({ 
        position: 'top-end',
        icon: 'error',
        title: '¡Usuario o contraseña incorrectos!',
        showConfirmButton: false,
        timer: 1500
      });
    }
    setLoading(false);
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  }

  return (
    <div className="View__Page-sc-1mn8xok-0 gHzPIp">
      <a href="#main-content" className="sc-faUpoM bqGgld sc-jlsrNB cKCdtp">Skip to main content</a>
      <div className="View__Header-sc-1mn8xok-1 bna-DAz"></div>
      <main id="main-content" className="View__Content-sc-1mn8xok-3 dRNZvP">
        <div className="View__FormBox-sc-1mn8xok-4 fpnjNW">
          <div className="View__Container-sc-1mn8xok-6 duSsWv">
            <h3 className="sc-dkPtRN View__Title-sc-1mn8xok-8 dnYEtG kBzQJs">Inicio de sesión</h3>
            <form action="#" className="View__Container-sc-eke0c0-0 ghFurQ" onSubmit={handleSubmit}>
              <div className="sc-giYglK ktrmkx Input__CustomInput-sc-1mfp81d-0 iMooCe View__CustomInput-sc-eke0c0-2 kYlZiX">
                  <div className="sc-egiyK bCRnWf"><label for="email"><span className="sc-furwcr sc-bqiRlB dJRzsx">Usuario</span></label></div>
                  <div className="sc-ikJyIC iZiwmQ">
                    <input type="text" data-qa="Email" placeholder="Usuario" id="email" name="email" className="sc-jJoQJp gguSTE" value={usuario} onChange={ (e) => setUsuario(e.target.value) } />
                  </div>
              </div>
              <div className="sc-giYglK ktrmkx Input__CustomInput-sc-1mfp81d-0 iMooCe View__CustomInput-sc-eke0c0-2 kYlZiX">
                  <div className="sc-egiyK bCRnWf">
                    <label for="password"><span className="sc-furwcr sc-bqiRlB dJRzsx">Contraseña</span></label>
                  </div>
                  <div className="sc-ikJyIC iZiwmQ">
                    <input type="password" data-qa="Contraseña" placeholder="Contraseña" id="password" name="password" className="sc-jJoQJp gguSTE" value={contrasenia} onChange={(e) => setContrasenia(e.target.value)} />
                  </div>
              </div>
              <a className="sc-fWCJzd dpogyX View__ForgotPasswordLink-sc-eke0c0-3 imNaNI">¿Olvidades la contraseña?</a>
              <button data-qa="Login" type="submit" className="sc-hBUSln bFdfPe View__Login-sc-eke0c0-1 gKwNdD">Iniciar sesión</button>
            </form>
          </div>
        </div>
        <div className="View__CarBox-sc-1mn8xok-5 lopxpH">
        </div>
    </main>
    <div className="View__Container-sc-1j74ed4-0 hoXJOD">

    </div>
</div>
  );
}