
export function formatCurrency(value, decimales = 2) {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimales, maximumFractionDigits: decimales }).format(value)
}

export function formatNumber(value, decimales = 2) {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: decimales, maximumFractionDigits: decimales }).format(value)
}

export function unformat(value) {
    const regex = new RegExp(`[^0-9-.]`, 'g');
     return parseFloat( (value)
        .replace(/\((?=\d+)(.*)\)/, '-$1') 
        .replace(regex, '') );
}

