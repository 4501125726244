import React, { useState, useEffect, Fragment } from 'react';
import './Vehiculos.css';
import CardVehiculo from './CardVehiculo';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Link,  useLocation, useNavigate,  } from 'react-router-dom';
import PaginationItem from '@mui/material/PaginationItem';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from '@mui/material/Slider';
import { formatCurrency, formatNumber, unformat } from '../../utils/utils';
import moment from 'moment';
import api,{ URL_API } from '../../utils/api';



import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';

export default function Cars() {
    let navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1');
    const [filtros,setFiltros] = useState([]);
    const [openFiltros,setOpenFiltros] = useState(false);
    const [precioDefault, setPrecioDefault] = useState([10499, 93499]);
    const [kilometrajeDefault, setKilometrajeDefault] = useState([10499, 93499]);
    const [anioDefault, setAnioDefault] = useState([1900, parseInt( moment().format('YYYY'))]);
    const [marca, setMarca] = useState([]);
    const [color, setColor] = useState([]);
    const [tipoVehiculo, setTipoVehiculo] = useState([]);
    const [transmision, setTransmision] = useState([]);
    const [motor, setMotor] = useState([]);
    const [precio, setPrecio] = useState([10499, 93499]);
    const [kilometraje, setKilometraje] = useState([10499, 93499]);
    const [anio, setAnio] = useState([1900, parseInt( moment().format('YYYY'))]);
    const [showMarcas, setShowMarcas] = useState(false);
    const [showColores, setShowColores] = useState(false);
    const [showTiposVehiculos, setShowTiposVehiculos] = useState(false);
    const [showTransmision, setShowTransmision] = useState(false);
    const [showMotor, setShowMotor] = useState(false);
    const [marcas,setMarcas] = useState([]);
    const [colores,setColores] = useState([]);
    const [motores,setMotores] = useState([]);
    const [tiposVehiculos,setTiposVehiculos] = useState([]);
    const [transmisiones,setTransmisiones] = useState([]);
    const [cars,setCars] = useState([]);
    const [limite, setLimite] = useState(12);
    const [registros, setRegistros] = useState(0);

    const handleClickMarca = (event, marcaCode) => {
        event && event.preventDefault();
        let marcaNew = marca;
        if(marcaNew.includes(marcaCode))
            marcaNew = [...marcaNew.filter(x => x !== marcaCode)];
        else
            marcaNew = [...marcaNew, marcaCode];
        const filtro = filtros.find(x => x.filtro === 'marca');
        if(filtro){
            filtro.valor = marcaNew;
            filtro.valorUrl = marcaNew.join(',');
        } else {
            filtros.push({
                filtro: 'marca',
                valor: marcaNew,
                valorUrl: marcaNew.join(',')
            });
        }
        setMarca(marcaNew);
        handleChangeNavigate([...filtros]);
    };

    const handleClickColor = (event, colorCode) => {
        event && event.preventDefault();
        let colorNew = color;
        if(colorNew.includes(colorCode))
            colorNew = [...colorNew.filter(x => x !== colorCode)];
        else
            colorNew = [...colorNew, colorCode];
        const filtro = filtros.find(x => x.filtro === 'color');
        if(filtro){
            filtro.valor = colorNew;
            filtro.valorUrl = colorNew.join(',');
        } else {
            filtros.push({
                filtro: 'color',
                valor: colorNew,
                valorUrl: colorNew.join(',')
            });
        }
        setColor(colorNew);
        handleChangeNavigate([...filtros]);
    };

    const handleClickTipoVehiculo = (event, tipoVehiculoCode) => {
        event && event.preventDefault();
        let tipoVehiculoNew = tipoVehiculo;
        if(tipoVehiculoNew.includes(tipoVehiculoCode))
            tipoVehiculoNew = [...tipoVehiculoNew.filter(x => x !== tipoVehiculoCode)];
        else
            tipoVehiculoNew = [...tipoVehiculoNew, tipoVehiculoCode];
        const filtro = filtros.find(x => x.filtro === 'tipo_vehiculo');
        if(filtro){
            filtro.valor = tipoVehiculoNew;
            filtro.valorUrl = tipoVehiculoNew.join(',');
        } else {
            filtros.push({
                filtro: 'tipo_vehiculo',
                valor: tipoVehiculoNew,
                valorUrl: tipoVehiculoNew.join(',')
            });
        }
        setTipoVehiculo(tipoVehiculoNew);
        handleChangeNavigate([...filtros]);
    };

    const handleClickTransmision = (event, transmisionCode) => {
        event && event.preventDefault();
        let transmisionNew = transmision;
        if(transmisionNew.includes(transmisionCode))
            transmisionNew = [...transmisionNew.filter(x => x !== transmisionCode)];
        else
            transmisionNew = [...transmisionNew, transmisionCode];
        const filtro = filtros.find(x => x.filtro === 'transmision');
        if(filtro){
            filtro.valor = transmisionNew;
            filtro.valorUrl = transmisionNew.join(',');
        } else {
            filtros.push({
                filtro: 'transmision',
                valor: transmisionNew,
                valorUrl: transmisionNew.join(',')
            });
        }
        setTransmision(transmisionNew);
        handleChangeNavigate([...filtros]);
    };

    const handleClickMotor = (event, motorCode) => {
        event && event.preventDefault();
        let motorNew = motor;
        if(motorNew.includes(motorCode))
            motorNew = [...motorNew.filter(x => x !== motorCode)];
        else
            motorNew = [...motorNew, motorCode];
        const filtro = filtros.find(x => x.filtro === 'motor');
        if(filtro){
            filtro.valor = motorNew;
            filtro.valorUrl = motorNew.join(',');
        } else {
            filtros.push({
                filtro: 'motor',
                valor: motorNew,
                valorUrl: motorNew.join(',')
            });
        }
        setMotor(motorNew);
        handleChangeNavigate([...filtros]);
    };

    const handleChangePrecio = (event, newPrecio) => {
        setPrecio(newPrecio);
    };

    const handleChangePrecioCommitted = (event, newValue) => {
        const filtro = filtros.find(x => x.filtro === 'precio');
        if(filtro){
            filtro.valor = newValue;
            filtro.valorUrl = newValue[0] +'-'+newValue[1];
        } else {
            filtros.push({
                filtro: 'precio',
                valor: newValue,
                valorUrl: newValue[0] +'-'+newValue[1]
            })
        }
        handleChangeNavigate([...filtros]);
    };

    const handleChangeNavigate = (filtros) => {
        setFiltros(filtros);
        const filtrosTodos = { page: page !== 1 ? page: 1 };
        for (let i = 0; i < filtros.length; i++) {
            const filtro = filtros[i];
            filtrosTodos[filtro.filtro] = filtro.valorUrl  
        }
        navigate("/cars?" +  Object.entries(filtrosTodos).map(([key,value]) => key+'='+value ).join('&'), { replace: true });
    };

    const handleChangeKilometraje = (event, newKilometraje) => {
        setKilometraje(newKilometraje);
    };

    const handleChangeKilometrajeCommitted = (event, newValue) => {
        const filtro = filtros.find(x => x.filtro === 'kilometraje');
        if(filtro){
            filtro.valor = newValue;
            filtro.valorUrl = newValue[0] +'-'+newValue[1];
        } else {
            filtros.push({
                filtro: 'kilometraje',
                valor: newValue,
                valorUrl: newValue[0] +'-'+newValue[1]
            });
        }
        handleChangeNavigate([...filtros]);
    };

    const handleChangeAnio = (event, newModelo) => {
        setAnio(newModelo);
    };

    const handleChangeAnioCommitted = (event, newValue) => {
        const filtro = filtros.find(x => x.filtro === 'anio');
        if(filtro){
            filtro.valor = newValue;
            filtro.valorUrl = newValue[0] +'-'+newValue[1];
        } else {
            filtros.push({
                filtro: 'anio',
                valor: newValue,
                valorUrl: newValue[0] +'-'+newValue[1]
            });
        }
        handleChangeNavigate([...filtros]);
    };
    
    async function getCars() {
        const filtrosEnviar = {};
        filtros.forEach(key => filtrosEnviar[key.filtro] = key.valor );
        const response = await api.get('vehiculos/busqueda', { params: { limite, pagina: page, ...filtrosEnviar } });
        setCars([
            ...response.data.vehiculos.map(x => ({
                ...x,
                activo: x.activo == 1,
                imagenes: [
                    ...x.imagenes.map(img => ({
                    src: URL_API + img.src, 
                    titulo: x.anio +' '+ x.marca_nombre +' '+ x.nombre, 
                    tipo: '' 
                    }))
                ]
            }))
        ])
        setRegistros(response.data.registros);
    }
    
    async function getCatalogos() {
        const { data } = await api.get('catalogos');
        setTransmisiones(data.transmisiones);
        setColores(data.colores);
        setMarcas(data.marcas);
        setMotores(data.motores);
        setTiposVehiculos(data.tipos_vehiculos);
        const filtrosIniciales = [];
        if(query.get('marca')) {
            setPrecio(query.get('marca').split(','));
            filtrosIniciales.push({
                filtro: 'marca',
                valor: query.get('marca').split(','),
                valorUrl: query.get('marca')
            });
        }
        if(query.get('modelo')) {
            setPrecio(query.get('modelo'));
            filtrosIniciales.push({
                filtro: 'modelo',
                valor: query.get('modelo'),
                valorUrl: query.get('modelo')
            });
        }
        if(query.get('transmision')) {
            setPrecio(query.get('transmision'));
            filtrosIniciales.push({
                filtro: 'transmision',
                valor: [query.get('transmision')],
                valorUrl: query.get('transmision')
            });
        }
        if(query.get('color')) {
            setPrecio(query.get('color'));
            filtrosIniciales.push({
                filtro: 'color',
                valor: [query.get('color')],
                valorUrl: query.get('color')
            });
        }
        if(query.get('motor')) {
            setPrecio(query.get('motor'));
            filtrosIniciales.push({
                filtro: 'motor',
                valor: [query.get('motor')],
                valorUrl: query.get('motor')
            });
        }
        if(query.get('tipo_vehiculo')) {
            setPrecio(query.get('tipo_vehiculo'));
            filtrosIniciales.push({
                filtro: 'tipo_vehiculo',
                valor: [query.get('tipo_vehiculo')],
                valorUrl: query.get('tipo_vehiculo')
            });
        }
        if(data.precio.length > 0) {
            if(query.get('precio')) {
                setPrecio([Number(query.get('precio').split('-')[0]),Number(query.get('precio').split('-')[1])]);
                filtrosIniciales.push({
                    filtro: 'precio',
                    valor: [Number(query.get('precio').split('-')[0]),Number(query.get('precio').split('-')[1])],
                    valorUrl: query.get('precio').split('-')[0] +'-'+query.get('precio').split('-')[1]
                });
            } else {
                setPrecio([Number(data.precio[0].min_precio), Number(data.precio[0].max_precio)]);
            }
            setPrecioDefault([Number(data.precio[0].min_precio), Number(data.precio[0].max_precio)]);
        }
        if(data.kilometraje.length > 0) {
            if(query.get('kilometraje')) {
                setKilometraje([Number(query.get('kilometraje').split('-')[0]),Number(query.get('kilometraje').split('-')[1])]);
                filtrosIniciales.push({
                    filtro: 'kilometraje',
                    valor: [Number(query.get('kilometraje').split('-')[0]),Number(query.get('kilometraje').split('-')[1])],
                    valorUrl: query.get('kilometraje').split('-')[0] +'-'+query.get('kilometraje').split('-')[1]
                });
            } else {
                setKilometraje([Number(data.kilometraje[0].min_kilometraje), Number(data.kilometraje[0].max_kilometraje)]);
            }
            setKilometrajeDefault([Number(data.kilometraje[0].min_kilometraje), Number(data.kilometraje[0].max_kilometraje)]);
        }
        if(data.anio.length > 0) {
            if(query.get('anio')) {
                setAnio([Number(query.get('anio').split('-')[0]),Number(query.get('anio').split('-')[1])]);
                filtrosIniciales.push({
                    filtro: 'anio',
                    valor: [Number(query.get('anio').split('-')[0]),Number(query.get('anio').split('-')[1])],
                    valorUrl: query.get('anio').split('-')[0] +'-'+query.get('anio').split('-')[1]
                });
            } else {
                setAnio([Number(data.anio[0].min_anio), Number(data.anio[0].max_anio)]);
            }
            setAnioDefault([Number(data.anio[0].min_anio), Number(data.anio[0].max_anio)]);
        }
        handleChangeNavigate(filtrosIniciales);
    }

    const renderTitle = (x) => {
        switch (x.filtro) {
            case 'precio':
                return formatCurrency(x.valor[0],0) + ' - ' + formatCurrency(x.valor[1],0)+ ' ' +  x.filtro;
            case 'kilometraje':
                return formatNumber(x.valor[0],0) + ' - ' + formatNumber(x.valor[1],0)+ ' ' +  x.filtro;
            case 'anio':
                return x.valor[0] + ' - ' + x.valor[1] +' ' +  x.filtro;
            default:
                return x.valor;
        }
    };

   
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const loading = open && options.length === 0;
    
    useEffect(() => {
        getCars();
    },[page, filtros]);

    useEffect(() => {
        getCatalogos();
    },[]);

    useEffect(() => {
        let active = true;
    
        if (!loading) {
          return undefined;
        }
    
        (async () => {
            const { data: { transmisiones, colores, marcas, motores, tipos_vehiculos} } = await api.get('catalogos', { params: { busqueda: inputValue } });
            const { data } = await api.get('vehiculos/autocomplete', { params: { busqueda: inputValue } });
          if (active) {
            setOptions([
                ...transmisiones.map(x => ({...x, agrupador: 'TRANSMISIONES', agrupador_codigo: 'transmision', autocomplete: x.nombre })),
                ...colores.map(x => ({...x, agrupador: 'COLORES', agrupador_codigo: 'color', autocomplete: x.nombre })),
                ...marcas.map(x => ({...x, agrupador: 'MARCAS', agrupador_codigo: 'marca', autocomplete: x.nombre })),
                ...motores.map(x => ({...x, agrupador: 'MOTORES', agrupador_codigo: 'motor', autocomplete: x.nombre })),
                ...tipos_vehiculos.map(x => ({...x, agrupador: 'TIPOS VEHICULOS', agrupador_codigo: 'tipo_vehiculo', autocomplete: x.nombre })),
                ...data.map(x => ({...x, agrupador: 'MODELOS', agrupador_codigo: 'modelo' })),
            ]);
          }
        })();
        return () => {
          active = false;
        };
      }, [loading]);
    
      useEffect(() => {
        if (!open) {
          setOptions([]);
        }
      }, [open]);

  return (
    <div id="main" className="page-content" data-v-8de4a01c="" data-v-27dbbde3="">
        <h1 className="u-sr" data-v-8de4a01c="">
            Vehículos
        </h1>
        <div className="u-bg-white" data-v-8de4a01c="">
            <div className="g-container g-container--large" data-v-8de4a01c="">
                <nav aria-label="Breadcrumb" data-v-72dfc844="" data-v-8de4a01c="">
                    <ul className="list" data-v-72dfc844="">
                        <li className="item" data-v-72dfc844="">
                            <span aria-hidden="true" className="separator" data-v-72dfc844="">/</span> 
                            <Link to="/cars" aria-current="page" className="link router-link-exact-active router-link-active" data-v-72dfc844="">
                                Todos los vehículos
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div className="mobile-vehicle-number" data-v-8de4a01c="">
                    {formatNumber(registros,0)} Vehículos
                </div>
                <div className="search-box search-box--mobile u-large-hidden" data-v-8de4a01c="">
                    <div className="hide-content-wrapper" data-v-8de4a01c="">
                        <Autocomplete
                            className='jss8 jss9'
                            freeSolo
                            groupBy={(option) => option.agrupador}
                            disableClearable
                            onChange={(event, value) => {
                                let filtrosTodos = {};
                                if(value && value.autocomplete){
                                    if(value.agrupador_codigo === 'modelo') {
                                        filtrosTodos = [
                                            {
                                                filtro: 'marca',
                                                valor: [value.codigo_marca],
                                                valorUrl: value.codigo_marca
                                            },
                                            {
                                                filtro: 'anio',
                                                valor: [value.anio,value.anio],
                                                valorUrl: value.anio + '-' + value.anio
                                            },
                                            {
                                                filtro: 'modelo',
                                                valor: value.modelo,
                                                valorUrl: value.modelo
                                            }
                                        ];
                                    } else {
                                        filtrosTodos = [
                                            {
                                                filtro: value.agrupador_codigo,
                                                valor: value.codigo,
                                                valorUrl: value.codigo
                                            }
                                        ];
                                    }
                                } else {
                                    filtrosTodos = [
                                        {
                                            filtro: 'modelo',
                                            valor: value,
                                            valorUrl: value
                                        }
                                    ];
                                }

                                setInputValue('');
                                handleChangeNavigate(filtrosTodos);
                            }}
                            value={null}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            inputValue={inputValue}
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.autocomplete === value.autocomplete}
                            getOptionLabel={(option) => option.autocomplete  || ''}
                            options={options}
                            loading={loading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                        type: 'search',
                                        className: 'jss6',
                                        placeholder: "Buscar por modelo o marca"
                                    }}
                                    className="jss6"
                                    fullWidth
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="sticky" data-v-8de4a01c="">
            <div className="g-container g-container--large sticky-container" data-v-8de4a01c="">
                <div className="search-box search-box--desktop u-large-block" data-v-8de4a01c="">
                    <div className="hide-content-wrapper" data-v-8de4a01c="">
                        <Autocomplete
                            className='jss8 jss9'
                            freeSolo
                            groupBy={(option) => option.agrupador}
                            disableClearable
                            onChange={(event, value) => {
                                let filtrosTodos = {};
                                if(value && value.autocomplete){
                                    if(value.agrupador_codigo === 'modelo') {
                                        filtrosTodos = [
                                            {
                                                filtro: 'marca',
                                                valor: [value.codigo_marca],
                                                valorUrl: value.codigo_marca
                                            },
                                            {
                                                filtro: 'anio',
                                                valor: [value.anio,value.anio],
                                                valorUrl: value.anio + '-' + value.anio
                                            },
                                            {
                                                filtro: 'modelo',
                                                valor: value.modelo,
                                                valorUrl: value.modelo
                                            }
                                        ];
                                    } else {
                                        filtrosTodos = [
                                            {
                                                filtro: value.agrupador_codigo,
                                                valor: [value.codigo],
                                                valorUrl: value.codigo
                                            }
                                        ];
                                    }
                                } else {
                                    filtrosTodos = [
                                        {
                                            filtro: 'modelo',
                                            valor: [value],
                                            valorUrl: value
                                        }
                                    ];
                                }

                                setInputValue('');
                                handleChangeNavigate(filtrosTodos);
                            }}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            value={null}
                            inputValue={inputValue}
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            isOptionEqualToValue={(option, value) => option.autocomplete === value.autocomplete}
                            getOptionLabel={(option) => option.autocomplete  || ''}
                            options={options}
                            loading={loading}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                        type: 'search',
                                        className: 'jss6',
                                        placeholder: "Buscar por modelo o marca"
                                    }}
                                    className="jss6"
                                    fullWidth
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="left-sticky" data-v-8de4a01c="">
                    <div className="vehicle-number-sticky" data-v-8de4a01c="">
                        {formatNumber(registros,0)} Vehículos
                    </div>
                    <div className="header show-on-large" data-v-8de4a01c="">
                        <h2 className="u-sr" data-v-8de4a01c="">
                            Filtro de busqueda
                        </h2>
                        <div className="header-item left" data-v-8de4a01c="">
                            <div className="filters-number" data-v-8de4a01c=""> Filtros {filtros.length > 0 && (<span className="u-fw400" data-v-8de4a01c="">({filtros.length})</span>) }</div>
                            <div className="selected-filters-desktop" data-v-8de4a01c="">
                                <ul className="list" data-v-64601363="" data-v-8de4a01c="">
                                    { 
                                        filtros.map((x,index) => (
                                            ['precio','kilometraje','anio','modelo'].includes(x.filtro) ? 
                                                (
                                                    <li data-v-64601363="" className="item">
                                                        { renderTitle(x)}
                                                        {/* <a href="#" data-v-64601363="" className="g-close g-close--white close router-link-active" onClick={() => handleClickRemoveFilter(x,index) }>
                                                            <span data-v-64601363="" className="u-sr">Borrar el filtro { renderTitle(x)} </span>
                                                        </a> */}
                                                    </li>
                                                ) : (
                                                    x.valor.map(y => (
                                                        <li data-v-64601363="" className="item">
                                                            { renderTitle({ filtro: x.filtro, valor: y })}
                                                            {/* <a href="#" data-v-64601363="" className="g-close g-close--white close router-link-active" onClick={() => handleClickRemoveFilter(x,index) }>
                                                                <span data-v-64601363="" className="u-sr">Borrar el filtro { renderTitle({ filtro: x.filtro, valor: y })} </span>
                                                            </a> */}
                                                        </li>
                                                    ))
                                                ) 
                                            ) 
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="header-item" data-v-8de4a01c="">
                            <a href="/cars" className="clear-all-btn router-link-active" data-v-8de4a01c="">
                                Limpiar filtros
                            </a>
                        </div>
                    </div>
                </div>
                <button type="button" className="filters-btn" data-v-8de4a01c=""  onClick={()=> setOpenFiltros(true)}>
                    Filtros
                    <span className="u-fw400" style={{display:'none'}} data-v-8de4a01c="">(0)</span>
                </button>
            </div>
        </div>
        <div className="g-container g-container--large bottom-container" data-v-8de4a01c="">
            <aside className={'drill '+ (openFiltros ? 'is-opened': null)} data-v-653d0a6a="" data-v-8de4a01c="">
                <h2 className="u-sr" data-v-653d0a6a="">
                    Filtro de busqueda
                </h2>
                <div className="header hide-on-large" data-v-653d0a6a="">
                    <a href="/cars" className="clear-all-btn router-link-active" data-v-653d0a6a="">
                        Limpiar todo
                    </a>
                    <div className="filters-number" data-v-653d0a6a="">
                        Filtros
                    </div> 
                    <button type="button" className="g-close close-drill-btn" data-v-653d0a6a="" onClick={()=> setOpenFiltros(false)}>
                        <span className="u-sr" data-v-653d0a6a="">Cerrar</span>
                    </button>
                    <div className="selected-filters" data-v-653d0a6a="">
                        <ul className="list" data-v-64601363="" data-v-653d0a6a="">
                        </ul>
                    </div>
                </div>
                <div className="wrap" data-v-04516fb7="" data-v-653d0a6a="">
                    <div className="item" data-v-04516fb7="">
                        <div className="g-heading1 u-fw600" data-v-04516fb7="">
                            Precio
                        </div>
                        <div data-v-6d6d3891="" data-v-04516fb7="">
                            <div className="top-wrap" data-v-6d6d3891="">
                                <div className="input-wrap" data-v-6d6d3891="">
                                    <label className="g-label" data-v-6d6d3891="">Min precio</label> 
                                    <input type="text" value={formatCurrency(precio[0],0)} onChange={(e) => setPrecio([unformat(e.target.value),precio[1]])} className="g-input" data-v-6d6d3891="" />
                                </div>
                                <div className="input-wrap" data-v-6d6d3891="">
                                    <label className="g-label" data-v-6d6d3891="">Max precio</label> 
                                    <input type="text" value={formatCurrency(precio[1],0)} onChange={(e) => setPrecio([precio[0],unformat(e.target.value)])} className="g-input" data-v-6d6d3891="" />
                                </div>
                            </div>
                            <Slider
                                value={precio}
                                onChange={handleChangePrecio}
                                onChangeCommitted={handleChangePrecioCommitted}
                                min={precioDefault[0]}
                                step={1}
                                max={precioDefault[1]}
                            />
                        </div>
                          {/* <div className="avg-price" data-v-04516fb7="">
                            Average Price: $30,999
                        </div> */}
                    </div>
                    <div className="item" data-v-04516fb7="">
                        <div className="g-heading1 u-fw600" data-v-04516fb7="">
                            Kilometraje
                        </div> 
                        <div data-v-6d6d3891="" data-v-04516fb7="">
                        <div className="top-wrap" data-v-6d6d3891="">
                            <div className="input-wrap" data-v-6d6d3891="">
                                <label className="g-label" data-v-6d6d3891="">Min kilometraje</label> 
                                <input type="text" value={formatNumber(kilometraje[0],0)} onChange={(e) => setKilometraje([unformat(e.target.value),kilometraje[1]])} className="g-input" data-v-6d6d3891="" />
                            </div>
                            <div className="input-wrap" data-v-6d6d3891="">
                                <label className="g-label" data-v-6d6d3891="">Max kilometraje</label> 
                                <input type="text" value={formatNumber(kilometraje[1],0)} onChange={(e) => setKilometraje([kilometraje[0],unformat(e.target.value)])} className="g-input" data-v-6d6d3891="" />
                            </div>
                        </div>
                        <Slider
                            value={kilometraje}
                            onChange={handleChangeKilometraje}
                            onChangeCommitted={handleChangeKilometrajeCommitted}
                            min={kilometrajeDefault[0]}
                            step={1}
                            max={kilometrajeDefault[1]}
                        />
                        </div>
                    </div>
                    <div className="item" data-v-04516fb7="">
                        <div className="g-heading1 u-fw600" data-v-04516fb7="">
                            Año
                        </div>
                        <div data-v-6d6d3891="" data-v-04516fb7="">
                            <div className="top-wrap" data-v-6d6d3891="">
                                <div className="input-wrap" data-v-6d6d3891="">
                                    <label className="g-label" data-v-6d6d3891="">Min año</label> 
                                    <input type="text" value={anio[0]} onChange={(e) => setAnio([e.target.value,anio[1]])} className="g-input" data-v-6d6d3891="" />
                                </div>
                                <div className="input-wrap" data-v-6d6d3891="">
                                    <label className="g-label" data-v-6d6d3891="">Max año</label> 
                                    <input type="text" value={anio[1]} onChange={(e) => setAnio([anio[0],e.target.value])} className="g-input" data-v-6d6d3891="" />
                                </div>
                            </div>
                            <Slider
                                value={anio}
                                onChange={handleChangeAnio}
                                onChangeCommitted={handleChangeAnioCommitted}
                                min={anioDefault[0]}
                                step={1}
                                max={ anioDefault[1] }
                            />
                        </div>
                    </div>
                </div>
                <div className="wrap" data-v-7b6ddd0d="" data-v-653d0a6a="">
                    <div data-v-7a749d04="" data-v-7b6ddd0d="">
                        <button className={'g-heading1 heading' + (showMarcas ? ' is-opened': '')} data-v-7a749d04="" onClick={() => setShowMarcas(!showMarcas)}>
                            <span className="heading-title" data-v-7a749d04="">Marca</span>
                        </button>
                    </div>
                    { showMarcas && (
                        <div data-v-7b6ddd0d="">
                            <div data-v-35311e47="" data-v-653d0a6a="" data-v-7b6ddd0d="">
                                <div data-v-35311e47="" className="options">
                                    <div data-v-35311e47="">
                                        <ul data-v-35311e47="" className="drill-list">
                                            {marcas.filter(x => Number(x.vehiculos)).map((m,key) => (
                                                <li data-v-35311e47="" key={key}>
                                                    <a data-v-35311e47="" href="#" onClick={(e) => handleClickMarca(e,m.codigo)} className={'list-link ' + (marca.includes(m.codigo) ? 'router-link-active is-selected': '')}>
                                                        <span data-v-35311e47="" className="">{m.nombre}</span> 
                                                        <span data-v-35311e47="" className="list-number">{m.vehiculos}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="wrap" data-v-7b6ddd0d="" data-v-653d0a6a="">
                    <div data-v-7a749d04="" data-v-7b6ddd0d="">
                        <button className={'g-heading1 heading' + (showColores ? ' is-opened': '')} data-v-7a749d04="" onClick={() => setShowColores(!showColores)}>
                            <span className="heading-title" data-v-7a749d04="">Color</span>
                        </button>
                    </div>
                    { showColores && (
                        <div data-v-7b6ddd0d="">
                            <div data-v-35311e47="" data-v-653d0a6a="" data-v-7b6ddd0d="">
                                <div data-v-35311e47="" className="options">
                                    <div data-v-35311e47="">
                                        <ul data-v-35311e47="" className="drill-list">
                                            {colores.filter(x => Number(x.vehiculos)).map((c,key) => (
                                                <li data-v-35311e47="" key={key}>
                                                    <a data-v-35311e47="" href="#" onClick={(e) => handleClickColor(e,c.codigo)} className={'list-link ' + (color.includes(c.codigo) ? 'router-link-active is-selected': '')}>
                                                        <span data-v-35311e47="" className={'color g-color--' + c.class}>{c.nombre}</span>
                                                        <span data-v-35311e47="" className="list-number">{c.vehiculos}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    {/* <button data-v-35311e47="" type="button" className="show-more-btn">
                                        Show More
                                    </button> */}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="wrap" data-v-7b6ddd0d="" data-v-653d0a6a="">
                    <div data-v-7a749d04="" data-v-7b6ddd0d="">
                        <button className={'g-heading1 heading' + (showTiposVehiculos ? ' is-opened': '')} data-v-7a749d04="" onClick={() => setShowTiposVehiculos(!showTiposVehiculos)}>
                            <span className="heading-title" data-v-7a749d04="">Tipo de vehículo</span>
                        </button>
                    </div>
                    { showTiposVehiculos && (
                        <div data-v-7b6ddd0d="">
                            <div data-v-35311e47="" data-v-653d0a6a="" data-v-7b6ddd0d="">
                                <div data-v-35311e47="" className="options">
                                    <div data-v-35311e47="">
                                        <ul data-v-35311e47="" className="drill-list">
                                            {tiposVehiculos.filter(x => Number(x.vehiculos)).map((c,key) => (
                                                <li data-v-35311e47="" key={key}>
                                                    <a data-v-35311e47="" href="#" onClick={(e) => handleClickTipoVehiculo(e,c.codigo)} className={'list-link ' + (tipoVehiculo.includes(c.codigo) ? 'router-link-active is-selected': '')}>
                                                        <span data-v-35311e47="" className={'bodystyle bodystyle--' + c.codigo}>{c.nombre}</span> 
                                                        <span data-v-35311e47="" className="list-number">{c.vehiculos}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    )}
                </div>
                <div className="wrap" data-v-7b6ddd0d="" data-v-653d0a6a="">
                    <div data-v-7a749d04="" data-v-7b6ddd0d="">
                        <button className={'g-heading1 heading' + (showTransmision ? ' is-opened': '')} data-v-7a749d04="" onClick={() => setShowTransmision(!showTransmision)}>
                            <span className="heading-title" data-v-7a749d04="">Transmisión</span>
                        </button>
                    </div>
                    { showTransmision && (
                        <div data-v-7b6ddd0d="">
                            <div data-v-35311e47="" data-v-653d0a6a="" data-v-7b6ddd0d="">
                                <div data-v-35311e47="" className="options">
                                    <div data-v-35311e47="">
                                        <ul data-v-35311e47="" className="drill-list">
                                            { transmisiones.filter(x => Number(x.vehiculos)).map((t,key) => (
                                                <li data-v-35311e47="" key={key}>
                                                    <a data-v-35311e47="" href="#" onClick={(e) => handleClickTransmision(e,t.codigo)} className={'list-link ' + (transmision.includes(t.codigo) ? 'router-link-active is-selected': '')}>
                                                        <span data-v-35311e47="" className="">{t.nombre}</span> 
                                                        <span data-v-35311e47="" className="list-number">{t.vehiculos}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="wrap" data-v-7b6ddd0d="" data-v-653d0a6a="">
                    <div data-v-7a749d04="" data-v-7b6ddd0d="">
                        <button className={'g-heading1 heading' + (showMotor ? ' is-opened': '')} data-v-7a749d04="" onClick={() => setShowMotor(!showMotor)}>
                            <span className="heading-title" data-v-7a749d04="">Motor</span>
                        </button>
                    </div>
                    { showMotor && (
                        <div data-v-7b6ddd0d="">
                            <div data-v-35311e47="" data-v-653d0a6a="" data-v-7b6ddd0d="">
                                <div data-v-35311e47="" className="options">
                                    <div data-v-35311e47="">
                                        <ul data-v-35311e47="" className="drill-list">
                                            { motores.filter(x => Number(x.vehiculos)).map((m,key) => (
                                                <li data-v-35311e47="" key={key}>
                                                    <a data-v-35311e47="" href="#" onClick={(e) => handleClickMotor(e,m.codigo)} className={'list-link ' + (motor.includes(m.codigo) ? 'router-link-active is-selected': '')}>
                                                        <span data-v-35311e47="" className="">{m.nombre}</span> 
                                                        <span data-v-35311e47="" className="list-number">{m.vehiculos}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="show-results-wrap" data-v-653d0a6a="" >
                    <button type="button" className="g-button g-button--full" data-v-653d0a6a=""  onClick={()=> setOpenFiltros(false)}>
                        Mirar todos {formatNumber(registros,0)}
                    </button>
                </div>
            </aside>
            <div className="search-right" data-v-8de4a01c="">
                <h2 className="u-sr" data-v-8de4a01c="">
                    Resultados de busqueda
                </h2>
                <div className="right-top" data-v-8de4a01c="">
                    <div className="desktop-vehicle-number" data-v-8de4a01c="">
                        {formatNumber(registros,0)} Vehículos
                    </div>
                </div>
                { cars.length === 0 ? 
                    (
                        <div data-v-8de4a01c="">
                            <p data-v-8de4a01c="" className="no-results">
                                No hay vahiculos para los parametros de su busqueda.<br data-v-8de4a01c="" />
                                Intete cambiar los criterios de busqueda.
                            </p>
                        </div> 
                    ) : (
                    <>
                        <div className="listings-wrap" data-v-8de4a01c="">
                            { cars.map((car,i)=> <CardVehiculo key={i} car={car} />) }
                        </div>
                        <div className="wrap" data-v-29ff0bce="" data-v-8de4a01c="">
                            <Stack spacing={2}>
                                <Pagination
                                page={page}
                                count={Math.ceil(registros / limite)}
                                className="list"
                                renderItem={(item) => (
                                    <PaginationItem
                                        component={Link}
                                        className="link"
                                        to={`/cars${item.page === 1 ? '' : `?page=${item.page}`}`}
                                        {...item}
                                    />
                                )}
                                />
                            </Stack>
                            <p data-v-29ff0bce="">
                                <span className="u-fw700" data-v-29ff0bce=""> {((page * (limite))-limite)+1}-{((page * (limite))-limite) + cars.length } of {formatNumber(registros,0)}</span> Vehiculos
                            </p>
                        </div>
                    </>
                )}
            </div>
        </div>
    </div>
  );
}