
import axios from 'axios';

export const URL_API = process.env.NODE_ENV === 'production'? 'https://confiautocln.mx/api/' : 'http://localhost:8081/confiautos/';
// export const URL_API = 'http://localhost:8081/confiautos/';
// export const URL_API = 'http://confiautocln.mx/api/';
const instance = axios.create({
    baseURL: URL_API,
    headers: {
        // 'Content-Type':'application/json',
        // 'Accept': "application/json"
    }
});

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem("auth");
    if(token)
        config.headers.Authorization =   `Bearer ${token}`;
    return config;
});
export default instance;