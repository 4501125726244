import React, { useState } from 'react';
import './Contacto.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import validator from 'validator';
import api from '../../utils/api';

export default function Contacto({ notification }) {
 
    

    const [nombre, setNombre] = useState('');
    const handleChangeNombre = (event) => {
    setNombre(event.target.value);
    };

    
    const [telefono, setTelefono] = useState('');
    const handleChangeTelefono = (event) => {
        setTelefono(event.target.value);
    };

    const [correo, setCorreo] = useState('');
    const handleChangeCorreo = (event) => {
        setCorreo(event.target.value);
    };


    const [marca, setMarca] = useState('');
    const handleChangeMarca = (event) => {
        setMarca(event.target.value);
    };

    const [anio, setAnio] = useState('');
    const handleChangeAnio = (event) => {
        setAnio(event.target.value);
    };
    
    const validar = (data) => {
        if(validator.isEmpty(data.nombre + ''))
        return { valido: false, mensaje: '¡El campo Nombre es requerido!'};
        if(validator.isEmpty(data.marca + ''))
        return { valido: false, mensaje: '¡El campo Teléfono es requerido!'};
        if(validator.isEmpty(data.correo + ''))
        return { valido: false, mensaje: '¡El campo Correo eléctronico es requerido!'};
        if(!validator.isEmail(data.correo + ''))
        return { valido: false, mensaje: '¡El campo Correo eléctronico debe tener un formato valido!'};
        if(validator.isEmpty(data.marca + ''))
        return { valido: false, mensaje: '¡El campo Marca de interes es requerido!'};
        return { valido: true };
    };

    const reload = async () => {
        setNombre('');
        setTelefono('');
        setCorreo('');
        setMarca('');
        setAnio('');
    }
    
    const handleClickEnviar = async () => {
        const formData = new FormData();
    
        formData.append('anio', anio);
        formData.append('marca', marca);
        formData.append('nombre', nombre);
        formData.append('telefono', telefono);
        formData.append('correo', correo);
    
        const validacion = validar({ anio,marca, nombre, telefono, correo }); 
        if(validacion.valido) {
          const res = await api.post('correos/enviar', formData);
          if(res.data){
              notification.fire({ 
                position: 'top-end',
                icon: 'success',
                title: '¡Correo enviado con éxito!',
                showConfirmButton: false,
                timer: 1500
              });
              reload();
          } else {
              notification.fire({ 
                position: 'top-end',
                icon: 'error',
                title: '¡Error al enviar el correo!',
                showConfirmButton: false,
                timer: 1500
              });
          }
        } else {
          notification.fire({ 
            position: 'top-end',
            icon: 'error',
            title: validacion.mensaje,
            showConfirmButton: false,
            timer: 1500
          });
        }
    }
  
    
  return (
    <div id="main-content">
      <div className="jss5 jss6a" data-element="Container" >
          <div className="jss7 jss8a" data-element="ContainerContent" >
              <div className="jss9 jss10" data-element="TextWrapper" >
                  <p className="MuiBox-root jss24 c jss16 jss11 jss12a">¿Necesitas ayuda?</p>
                  <h1 className="MuiBox-root jss27 jss13a jss14 jss25 jss26a">¡Contactanos!</h1>
                  <p className="MuiBox-root jss30a jss13a jss16 jss28a jss29a">Contestamos todas tus dudas de manera clara, llamanos al (667) 544 5969
                  </p>
                  <div className="jss31 jss32" data-element="PreQualWrapper" >
                    <a style={{position: 'relative', paddingLeft: '45px'}} data-v-0a2dfc35="" href={`https://api.whatsapp.com/send?phone=526675445969`} target="_blank" rel="noopener noreferrer"  className="g-button g-button--secondary get-started">
                        <WhatsAppIcon style={{    position: 'absolute', left: '15px', top: '13px' }}></WhatsAppIcon>
                        CONTACTAR
                    </a>
                  </div>
              </div>
              <div className="jss33 jss34" data-element="ImageWrapper" >
            </div>
          </div>
      </div>
      <div className="jss257 jss258" data-element="Container">
        <h2 className="MuiBox-root jss261 jss13c jss15 jss259 jss260 form-contacto">Agenda tu cita</h2>
        <div action="#" className="sc-lheXJl kRdoR form-contacto">
            <div className="sc-giYglK ktrmkx sc-leSONj eoOSap">
                <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Nombre <span style={{color: 'red'}}>*</span></span></label></div>
                <div className="sc-ikJyIC iZiwmQ"><input  placeholder="Nombre" className="sc-jJoQJp jjlIvY" value={nombre} onChange={handleChangeNombre}  /></div>
            </div>
            <div className="sc-cAhXWc keUCuc">
                <div className="sc-giYglK ktrmkx sc-hmvnCu idayyE">
                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Teléfono <span style={{color: 'red'}}>*</span> </span></label></div>
                    <div className="sc-ikJyIC iZiwmQ"><input placeholder="Teléfono" className="sc-jJoQJp jjlIvY" value={telefono} onChange={handleChangeTelefono}  /></div>
                </div>
                <div className="sc-gWXbKe byPIxE sc-gsFzgR fXipfN">
                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Correo eléctronico <span style={{color: 'red'}}>*</span> </span></label></div>
                    <div className="sc-ikJyIC iZiwmQ"><input type="email" placeholder="Correo eléctronico" className="sc-jJoQJp jjlIvY" value={correo} onChange={handleChangeCorreo}  /></div>
                </div>
            </div>
            <div className="sc-cAhXWc keUCuc">
                <div className="sc-giYglK ktrmkx sc-hmvnCu idayyE">
                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Marca de interes <span style={{color: 'red'}}>*</span></span></label></div>
                    <div className="sc-ikJyIC iZiwmQ"><input  placeholder="Marca de interes" className="sc-jJoQJp jjlIvY" value={marca} onChange={handleChangeMarca}  /></div>
                </div>
                <div className="sc-gWXbKe byPIxE sc-gsFzgR fXipfN">
                    <div className="sc-egiyK bCRnWf"><label><span className="sc-furwcr sc-bqiRlB dJRzsx">Año</span></label></div>
                    <div className="sc-ikJyIC iZiwmQ"><input type="number" placeholder="Año" className="sc-jJoQJp jjlIvY" value={anio} onChange={handleChangeAnio} /></div>
                </div>
            </div>
        </div>
        <div className='form-contacto' >
            <div style={{ marginTop: '20px'}}></div>
            <button className="sc-hBUSln bFdfPe sc-hoHwyw PmcJM" onClick={handleClickEnviar}>Enviar</button>
            <span className="sc-furwcr sc-kLnunm dJRzsx ldKfSs"></span>
        </div>
      </div>
      <div className="jss176a jss177a" data-element="Container" >
            <h2 className="MuiBox-root jss180 jss13b jss15a jss178 jss179a">¿Preguntas?</h2>
            <h2 className="MuiBox-root jss180 jss13b jss15a jss178 jss179a">Horario de atención: 9:00 am a 6:00pm De Lunes a Viernes</h2>
            <div className="jss181 jss182" data-element="ContainerContent" ><a href="/contact" className="sc-dvQaRk jcGXgw">
                    <div className="jss183 jss190"><svg className="sc-jrQzAO bsMzcy jss185 jss191" fill="#131313" stroke="none"
                            width="24" height="14" viewBox="0 0 24 14">
                            <path
                                d="M2.36 0L0 14H21.64L24 0H2.36ZM19.77 2L11.14 7L5.74 2H19.77ZM20 12H2.37L3.88 3L10.88 9.41L21.42 3.41L20 12Z">
                            </path>
                        </svg>
                        <p className="MuiBox-root jss193 jss13b jss16 jss187 jss192">Enviar mensaje</p>
                    </div>
                </a><a href="tel:1-(667) 544 5969}" className="sc-dvQaRk jcGXgw">
                    <div className="jss183 jss194a"><svg className="sc-jrQzAO cdPXSW jss185 jss195" fill="#131313" stroke="none"
                            width="22" height="20" viewBox="0 0 22 20">
                            <path
                                d="M3.46015 2.84003C3.91026 1.16645 5.4567 0 7.22535 0H15.5716L12.976 7.61905H9.11524L7.72474 12.381H12.0611L10.1144 20H3.89548C1.34268 20 -0.519396 17.6365 0.130274 15.221L3.46015 2.84003ZM7.22535 1.90476C6.34103 1.90476 5.56781 2.48799 5.34275 3.32477L2.01288 15.7057C1.68804 16.9135 2.61908 18.0952 3.89548 18.0952H8.59442L9.56777 14.2857H5.14395L7.64684 5.71429H11.5729L12.8707 1.90476H7.22535ZM19.9489 11.1184C20.2863 8.75272 19.8265 6.29004 18.3196 4.39423L19.8562 3.22482C21.7559 5.61472 22.2696 8.62823 21.8769 11.3816C21.4859 14.1233 20.1791 16.7337 18.2613 18.3421L16.9944 16.8959C18.4833 15.6472 19.6099 13.4957 19.9489 11.1184ZM15.8082 10.436C15.9115 9.47593 15.6928 8.63877 15.358 8.14733L16.9777 7.09076C17.6162 8.0279 17.8842 9.3336 17.7442 10.6354C17.6021 11.9565 17.0322 13.3582 15.8827 14.483L14.5062 13.1361C15.3034 12.356 15.707 11.3769 15.8082 10.436Z">
                            </path>
                        </svg>
                        <p className="MuiBox-root jss197 jss13b jss16 jss187 jss196">(667) 544 - 5969</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
  );
}