import React, { useState, useEffect, Fragment } from 'react';
import Select from 'react-select';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { Link,  useLocation } from 'react-router-dom';
import PaginationItem from '@mui/material/PaginationItem';
import Button from '@mui/material/Button';
import ImageUploading from "react-images-uploading";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

import { formatCurrency, formatNumber, unformat } from '../../utils/utils';
import moment from 'moment';
import api,{ URL_API } from '../../utils/api';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import CloseIcon from '@mui/icons-material/Close';
import './Dashboard.css';
import CardVehiculo from './CardVehiculo';
import NuevoVehiculo from './NuevoVehiculo';

import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';

export default function Dashboard(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);
  const [limite, setLimite] = useState(12);
  const [registros, setRegistros] = useState(0);
  const [marcas,setMarcas] = useState([]);
  const [colores,setColores] = useState([]);
  const [motores,setMotores] = useState([]);
  const [transmisiones,setTransmisiones] = useState([]);
  const [tipos_vehiculos,setTiposVehiculos] = useState([]);
  const [cars,setCars] = useState([]);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const loading = open && options.length === 0;
  const [filtros,setFiltros] = useState({});

  async function getCars() {
    const response = await api.get('vehiculos/paginado', { params: { limite, pagina: page, ...filtros } });
    setCars([
        ...response.data.vehiculos.map(x => ({
            ...x, 
            activo: x.activo == 1,
            imagenes: [
                ...x.imagenes.map(img => ({
                  src: URL_API + img.src, 
                  titulo: x.modelo +' '+ x.marca_nombre +' '+ x.nombre, 
                  tipo: '' 
                }))
              ]
        }))
    ])
    setRegistros(response.data.registros);
  }

  async function getCatalogos() {
    const response = await api.get('catalogos');
    setTransmisiones(response.data.transmisiones);
    setTiposVehiculos(response.data.tipos_vehiculos);
    setColores(response.data.colores);
    setMarcas(response.data.marcas);
    setMotores(response.data.motores);
  }

  useEffect(() => {
    getCars();
  },[filtros]);

  useEffect(() => {
    getCars();
  },[page]);

  useEffect(() => {
    getCatalogos();
  },[]);
  
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const { data: { transmisiones, colores, marcas, motores, tipos_vehiculos} } = await api.get('catalogos', { params: { busqueda: inputValue } });
      const { data } = await api.get('vehiculos/autocomplete', { params: { busqueda: inputValue } });
      if (active) {
        setOptions([
            ...transmisiones.map(x => ({...x, agrupador: 'TRANSMISIONES', agrupador_codigo: 'transmision', autocomplete: x.nombre })),
            ...colores.map(x => ({...x, agrupador: 'COLORES', agrupador_codigo: 'color', autocomplete: x.nombre })),
            ...marcas.map(x => ({...x, agrupador: 'MARCAS', agrupador_codigo: 'marca', autocomplete: x.nombre })),
            ...motores.map(x => ({...x, agrupador: 'MOTORES', agrupador_codigo: 'motor', autocomplete: x.nombre })),
            ...tipos_vehiculos.map(x => ({...x, agrupador: 'TIPOS VEHICULOS', agrupador_codigo: 'tipo_vehiculo', autocomplete: x.nombre })),
            ...data.map(x => ({...x, agrupador: 'MODELOS', agrupador_codigo: 'modelo' })),
        ]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
      <div id="main" className="page-content" data-v-8de4a01c="" data-v-27dbbde3="">
        <h1 className="u-sr" data-v-8de4a01c="">
            Used Cars For Sale
        </h1>
        <div className="u-bg-white" data-v-8de4a01c="">
            <div className="g-container g-container--large" data-v-8de4a01c="">
                <div className="mobile-vehicle-number" data-v-8de4a01c="">
                    {formatNumber(registros,0)} Vehículos
                </div>
                <div className="search-box search-box--mobile u-large-hidden" data-v-8de4a01c="">
                    <div className="hide-content-wrapper" data-v-8de4a01c="">
                      <Grid container spacing={2}>
                        <Grid item xs={8} md={10}>
                          <Autocomplete
                                className='jss8 jss9'
                                freeSolo
                                groupBy={(option) => option.agrupador}
                                onChange={(event, value) => {
                                    let filtrosTodos = {};
                                    if(value && value.autocomplete){
                                        if(value.agrupador_codigo === 'modelo') {
                                            filtrosTodos = {
                                                modelo:  value.modelo,
                                                marca:  value.codigo_marca,
                                                anio:  [value.anio,value.anio],
                                            };
                                        } else {
                                            filtrosTodos = {
                                                [value.agrupador_codigo]:  value.codigo,
                                            };
                                        }
                                    } else {
                                        filtrosTodos = {
                                            modelo:  value
                                        };
                                    }
                                    setFiltros(filtrosTodos);
                                    setInputValue('');
                                    // return navigate("/cars?" +  Object.entries(filtrosTodos).map(([key,value]) => key + '=' + value ).join('&'), { replace: true });
                                }}
                                onInputChange={(event, newInputValue) => {
                                  setInputValue(newInputValue);
                                }}
                                inputValue={inputValue}
                                open={open}
                                onOpen={() => {
                                  setOpen(true);
                                }}
                                onClose={() => {
                                  setOpen(false);
                                }}
                                isOptionEqualToValue={(option, value) => option.autocomplete === value.autocomplete}
                                getOptionLabel={(option) => option.autocomplete}
                                options={options}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                              <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                              </React.Fragment>
                                            ),
                                            type: 'text',
                                            className: 'jss6',
                                            placeholder: "Buscar por modelo o marca"
                                        }}
                                        className="jss6"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4} md={2}>
                          <NuevoVehiculo
                            marcas={marcas}
                            colores={colores}
                            motores={motores}
                            tipos_vehiculos={tipos_vehiculos}
                            transmisiones={transmisiones}
                            reload={getCars}
                            notification={props.notification}
                          />
                        </Grid>
                      </Grid>
                    </div>
                </div>
            </div>
        </div>
        <div className="sticky" data-v-8de4a01c="">
            <div className="g-container g-container--large sticky-container" data-v-8de4a01c="">
                <div className="search-box search-box--desktop u-large-block" data-v-8de4a01c="">
                    <div className="hide-content-wrapper" data-v-8de4a01c="">
                      <Grid container spacing={2}>
                        <Grid item xs={6} md={10}>
                            <Autocomplete
                              className='jss8 jss9'
                              freeSolo
                              groupBy={(option) => option.agrupador}
                              onChange={(event, value) => {
                                  let filtrosTodos = {};
                                  if(value && value.autocomplete){
                                      if(value.agrupador_codigo === 'modelo') {
                                          filtrosTodos = {
                                              modelo:  value.modelo,
                                              marca:  value.codigo_marca,
                                              anio:  [value.anio,value.anio],
                                          };
                                      } else {
                                          filtrosTodos = {
                                              [value.agrupador_codigo]:  value.codigo,
                                          };
                                      }
                                  } else {
                                      filtrosTodos = {
                                          modelo:  value
                                      };
                                  }
                                  setFiltros(filtrosTodos);
                                  setInputValue('');
                                  // return navigate("/cars?" +  Object.entries(filtrosTodos).map(([key,value]) => key + '=' + value ).join('&'), { replace: true });
                              }}
                              onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                              }}
                              inputValue={inputValue}
                              open={open}
                              onOpen={() => {
                                setOpen(true);
                              }}
                              onClose={() => {
                                setOpen(false);
                              }}
                              isOptionEqualToValue={(option, value) => option.autocomplete === value.autocomplete}
                              getOptionLabel={(option) => option.autocomplete}
                              options={options}
                              loading={loading}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      InputProps={{
                                          ...params.InputProps,
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  <SearchIcon />
                                              </InputAdornment>
                                          ),
                                          endAdornment: (
                                            <React.Fragment>
                                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                              {params.InputProps.endAdornment}
                                            </React.Fragment>
                                          ),
                                          type: 'text',
                                          className: 'jss6',
                                          placeholder: "Buscar por modelo o marca"
                                      }}
                                      className="jss6"
                                      fullWidth
                                  />
                              )}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <NuevoVehiculo
                            marcas={marcas}
                            colores={colores}
                            motores={motores}
                            tipos_vehiculos={tipos_vehiculos}
                            transmisiones={transmisiones}
                            reload={getCars}
                            notification={props.notification}
                          />
                        </Grid>
                      </Grid>
                    </div>
                </div>
            </div>
        </div>
        <div className="g-container g-container--large bottom-container" data-v-8de4a01c="">
            <div className="search-right" data-v-8de4a01c="">
                <h2 className="u-sr" data-v-8de4a01c="">
                    Resultados de busqueda
                </h2>
                <div className="right-top" data-v-8de4a01c="">
                    <div className="desktop-vehicle-number" data-v-8de4a01c="">
                        {formatNumber(registros,0)} Vehículos
                    </div>
                </div>
                { cars.length > 0 && (
                  <>
                    <div className="listings-wrap" data-v-8de4a01c="">
                      { cars.map((car,i)=> (
                        <CardVehiculo
                          key={i}
                          car={car} 
                          notification={props.notification} 
                          reload={getCars} 
                          marcas={marcas}
                          tipos_vehiculos={tipos_vehiculos}
                          transmisiones={transmisiones}
                          motores={motores}
                          colores={colores}
                        />)) }
                    </div>
                    <div className="wrap" data-v-29ff0bce="" data-v-8de4a01c="">
                      <Stack spacing={2}>
                        <Pagination
                          page={page}
                          count={Math.ceil(registros / limite)}
                          className="list"
                          renderItem={(item) => (
                            <PaginationItem
                              component={Link}
                              className="link"
                              to={`/dashboard${item.page === 1 ? '' : `?page=${item.page}`}`}
                              {...item}
                            />
                          )}
                        />
                      </Stack>
                      <p data-v-29ff0bce="">
                          <span className="u-fw700" data-v-29ff0bce=""> {((page * (limite))-limite)+1}-{((page * (limite))-limite) + cars.length } of {formatNumber(registros,0)}</span> Vehículos
                      </p>
                    </div>
                  </>
                )}
                
            </div>
        </div>
    </div>
  );
}