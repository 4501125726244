import React from 'react';
import './TerminosCondiciones.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function TerminosCondiciones() {
  return (
    <div id="main-content">
      <div className="jss5 jss6a" data-element="Container"  >
          <div className="jss7 jss8a" data-element="ContainerContent" >
              <div className="jss9 jss101" data-element="TextWrapper" >
                  <h1 className="MuiBox-root jss27 jss13a jss14 jss25 jss26a">TÉRMINOS Y CONDICIONES</h1>
              </div>
              <div className="jss33 jss34" data-element="ImageWrapper" >
            </div>
          </div>
      </div>
      <div className="styled__SectionWrapper-sc-sy1efh-0 bGRXzR">
    <section id="tou-page"  className="styled__Section-sc-sy1efh-1 eEWXeE">
        <div className="styled__TitleRow-sc-sy1efh-2 eGphEs">
            <h2 className="sc-gsDKAQ styled__Title-sc-sy1efh-3 gsoPfm"></h2>
            <span className="sc-iCfMLu styled__LastUpdated-sc-sy1efh-5 gTDDhu">Última modificación en el presente aviso de privacidad: 19 de Enero, 2023.</span>
        </div>
        <ol className="legal-sections">
            <li>
                TÉRMINOS Y CONDICIONES
                <p>
                    El uso de nuestros servicios así como la compra de nuestros productos implicará que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento. Todas los productos que son ofrecidos por nuestro sitio web pudieran ser creadas, cobradas, enviadas o presentadas por una página web tercera y en tal caso estarían sujetas a sus propios Términos y Condiciones. 
                </p>
                <p>
                    Todas las compras y transacciones que se lleven a cabo por medio de whatsapp, están sujetas a un proceso de confirmación y verificación, el cual podría incluir la verificación del stock y disponibilidad de producto, validación de la forma de pago, validación de la factura (en caso de existir) y el cumplimiento de las condiciones requeridas por el medio de pago seleccionado. En algunos casos puede que se requiera una verificación por medio de correo electrónico.
                </p>
                <p>
                    Los precios de los productos ofrecidos en este Catalogo Online es válido solamente en las compras realizadas a través de cotizaciones realizadas por whatsapp.
                </p>
            </li>
            <li>
                USO NO AUTORIZADO
                <p>
                    En caso de que aplique (para venta de software, templetes, u otro producto de diseño y programación) usted no puede colocar uno de nuestros productos, modificado o sin modificar, en un CD, sitio web o ningún otro medio y ofrecerlos para la redistribución o la reventa de ningún tipo.
                </p>
            </li>
            <li>
                PROPIEDAD
                <p>
                    Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar. Todos los productos son propiedad de los proveedores del contenido. En caso de que no se especifique lo contrario, nuestros productos se proporcionan sin ningún tipo de garantía, expresa o implícita. 
                </p>
                <p>
                    En ningún esta compañía será responsables de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos.
                </p>
            </li>
            <li>
                POLÍTICA DE REEMBOLSO Y GARANTÍA
                <p>
                    En tales casos la garantía solo cubrirá fallas de fábrica y sólo se hará efectiva cuando el producto se haya usado correctamente. La garantía no cubre averías o daños ocasionados por uso indebido. Los términos de la garantía están asociados a fallas de fabricación y funcionamiento en condiciones normales de los productos y sólo se harán efectivos estos términos si el equipo ha sido usado correctamente. 
                </p>
            </li>
            <li>
            PRIVACIDAD
                <p>
                    http://confiautocln.mx/ garantiza que la información personal que usted envía mediante whatsapp cuenta con la seguridad necesaria.
                </p>
                <p>
                    ConfiAutocln reserva los derechos de cambiar o de modificar estos términos sin previo aviso.
                </p>
            </li>
        </ol>
    </section>
</div>
    </div>
  );
}