import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/lazy";
import { Navigation, Pagination, Lazy } from "swiper";
import { formatCurrency, formatNumber } from '../../utils/utils';
import { Link } from 'react-router-dom';
import './Vehiculos.css';

export default function CardVehiculo({ car }) {
  
    return (
        <article className="wrap" data-v-96f9fdd4="" data-v-8de4a01c="">
          <Link to={'/car/' + car.kebab_name} target="_self" className="listing-link" data-v-96f9fdd4="">
                <div className="images" data-v-96f9fdd4="">
                    <Swiper 
                        navigation={true} 
                        preloadImages={false}
                        lazy={{
                            enabled: true,
                            loadPrevNext: true, 
                            loadPrevNextAmount: 2 
                        }}
                        modules={[Navigation,Pagination, Lazy]} 
                        loop={true}
                        pagination={{ clickable: true }}
                    >
                        { car.imagenes.filter((x,i)=> i < 4 ).map((image,i) => (
                          <SwiperSlide key={i}>
                             {({ isActive }) => (
                                <div className="img-wrap" data-v-96f9fdd4="">
                                    <picture data-v-96f9fdd4="">
                                        { isActive && (<img alt={image.titulo} data-sizes="auto" className="img lazyautosizes lazyloaded" data-v-96f9fdd4="" sizes="333px" src={image.src} />)}
                                    </picture>
                                </div>
                            )}
                          </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                { car.enganche > 0 && car.mensualidad > 0 && car.plazos > 0 && (
                    <div data-v-727f32ec="" style={{color: '#fff', fontSize: '1em',  fontWeight: '800', letterSpacing: '.07rem', textTransform: 'uppercase', width: '100%', background: 'rgb(33, 33, 33)', border: 'none', textAlign: 'center', padding: '2px'}}>
                        <span data-v-727f32ec="" >
                            {formatCurrency(car.enganche,0)} de enganche. <br /> {formatCurrency(car.mensualidad,0)} a {car.plazos} meses
                        </span>
                    </div>
                )}
                <div className="details" data-v-96f9fdd4="">
                    <div data-v-727f32ec="" className="old-price">
                        <span data-v-727f32ec="" style={{color: '#941b1c'}}>
                            * No incluye seguro
                        </span>
                    </div>
                    <div className="details-wrap" data-v-96f9fdd4="">
                        <div data-v-96f9fdd4="">
                            <h3 className="title" data-v-96f9fdd4="">
                                {car.anio} {car.marca_nombre} {car.modelo}
                            </h3>
                            <div data-v-96f9fdd4="">{car.edicion} {car.edicion && '/'} {formatNumber(car.kilometraje,0)} kilómetros</div>
                        </div>
                        <div className="details-right" data-v-96f9fdd4="">
                            <div className="price" data-v-96f9fdd4="">
                                {formatCurrency(car.precio,0)}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </article>
     );
}