import React from 'react';
import './Nosotros.css';

export default function Nosotros() {
  return (
    <div id="main-content">
        <div data-element="Container"  className="Hero__Container-sc-1qcujmp-0 jVpyyj">
            <div data-element="Content"  className="Hero__Content-sc-1qcujmp-1 jLLoWi">
                <h1 data-element="StyledTitle"  className="sc-bdvvtL Hero__StyledTitle-sc-1qcujmp-2 boUWED UBXRd">Nosotros</h1>
                <div id="complimentary-coverage" className="View__Content-sc-t5xkws-0 jxAkaj">
                <span className="sc-eCImPb View__StyledHeading-sc-t5xkws-1 fTmPBJ gHGxeC">Tú próximo auto está con nosotros</span>
                    <div className="View__Grid-sc-t5xkws-2 bVAPNK">
                        <div className="View__Section-sc-t5xkws-3 cFAkoX">
                        <img src="/free-appraisal.svg" alt="Compara" width="44px" height="40px" />
                        <span className="sc-gKclnd dyesrI">Compara</span>
                        <span className="sc-iCfMLu gTDDhu">En ConfiautoCLN descubre todos los detalles que debes saber sobre el auto de tus sueños y compara la mejor opción de compra según tus necesidades e intereses.</span>
                        </div>
                        <div className="View__Section-sc-t5xkws-3 cFAkoX">
                        <img src="/browse.svg" alt="Busca" width="44px" height="40px" />
                        <span className="sc-gKclnd dyesrI">Busca</span>
                        <span className="sc-iCfMLu gTDDhu">Conoce toda la información en la industria automotriz: Características, guía de precios. Nos dedicamos a encontrar el auto que requieres, haciendo que el proceso de compra sea satisfactorio, con la asesoría para adquirir un crédito en caso de que tu compra así lo requiera.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div data-element="Container" className="sc-bOTDDd dMWZGI">
            <div data-element="Section" className="sc-hrJsxi gdDrAZ">
                <h2 data-v-9f4e18d2="" id="similars-title" className="sc-gsDKAQ sc-jRyozQ gsoPfm kHZKIa">
                    Contamos con financiamiento en
                </h2>
                <div data-element="BrandImageContainer" className="sc-hRnpUl jWIpml">
                    <div data-qa="Banorte" className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                        <div className="sc-eEvSnX fcYBqO">
                            <img src="./bancos/banorte.png" alt="Banorte logo" loading="lazy" className="sc-epFoly ftGILD" style={{maxWidth: '200px', height: 'auto'}} />
                        </div>
                    </div>
                    <div data-qa="Banregio" className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                        <div className="sc-eEvSnX fcYBqO">
                            <img src="./bancos/banregio.png" alt="Banregio logo" loading="lazy" className="sc-epFoly ftGILD" style={{maxWidth: '200px', height: 'auto'}} />
                        </div>
                    </div>
                    <div data-qa="Hey" className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                        <div className="sc-eEvSnX fcYBqO">
                            <img src="./bancos/hey.png" alt="Hey logo" loading="lazy" className="sc-epFoly ftGILD" style={{maxWidth: '200px', height: 'auto'}} />
                        </div>
                    </div>
                    <div data-qa="HSBC" className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                        <div className="sc-eEvSnX fcYBqO">
                            <img src="./bancos/HSBC.png" alt="HSBC logo" loading="lazy" className="sc-epFoly ftGILD" style={{maxWidth: '200px', height: 'auto'}} />
                        </div>
                    </div>
                    <div data-qa="CI Banco" className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                        <div className="sc-eEvSnX fcYBqO">
                            <img src="./bancos/Logo_de_CIBanco.svg.png" alt="CI Banco logo" loading="lazy" className="sc-epFoly ftGILD" style={{maxWidth: '200px', height: 'auto'}} />
                        </div>
                    </div>
                    <div data-qa="Scotiabank" className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                        <div className="sc-eEvSnX fcYBqO">
                            <img src="./bancos/Scotiabankpng.png" alt="Scotiabank logo" loading="lazy" className="sc-epFoly ftGILD" style={{maxWidth: '200px', height: 'auto'}} />
                        </div>
                    </div>
              </div>
          </div>
      </div>
  </div>
  );
}