import React, { useState, useEffect } from 'react';
import { Navigate, BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import Inicio from '../Inicio/Inicio';
import Dashboard from '../Dashboard/Dashboard';
import Login from '../Login/Login';
import Vehiculos from '../Vehiculos/Vehiculos';
import Vehiculo from '../Vehiculo/Vehiculo';

import Nosotros from '../Nosotros/Nosotros';
import Contacto from '../Contacto/Contacto';
import TerminosCondiciones from '../TerminosCondiciones/TerminosCondiciones';

import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import SortIcon from '@mui/icons-material/Sort';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useJwt } from "react-jwt";

const pages = [{ title: 'Vehículos', src: 'cars'},{ title: 'Nosotros', src: 'nosotros'},{ title: 'Contacto', src: 'contacto'}];


const MySwal = withReactContent(Swal)


function App() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("auth"));
  
  const { decodedToken, isExpired } = useJwt(localStorage.getItem("auth"));
  const [username, setUsername] = useState('');
  const [accordion, setAcordion] = useState({});

  useEffect(() => {
    if(decodedToken && !username){
        setUsername(decodedToken ? decodedToken.sub: '');
    }
    if(token && isExpired){
        handleToken('');
    } else {
        setUsername(decodedToken ? decodedToken.sub: '');
    }
  }, [decodedToken, isExpired]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElNav(null);
    setAnchorElUser(null);
  };

  const handleToken = (token) => {
    setToken(token);
    localStorage.setItem("auth", token);
  };

  return (
    <div id="__next">
        <a href="#main-content" style={{ marginTop:'1em', marginLeft:'1em'}} className="sc-faUpoM bqGgld sc-jlsrNB cKCdtp">Skip to main content</a>
        <Router>
            <Container maxWidth="xl2" disableGutters className="jss1 jss2">
                <div className="jss3 jss4">
                    <AppBar position="sticky" className="header">
                        <Container disableGutters className="jss1 jss2">
                            <Box className="sc-cHzqoD aFaxi" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                <Link to="/" className="sc-JkixQ ieHePb">
                                    <img src="./logoconfiauto.png" alt="Logo" style={{ width: '115px', position: 'absolute', margin: 0, top: '-20px', left: '19px'}}/>
                                </Link>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                <SortIcon/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                    }}
                                >
                                
                                </Menu>
                            </Box>
                            <Box sx={{ flexGrow: 0 }} className="sc-gUQvok eMvhQw">
                                <Link to="/" className="sc-dvQaRk jcGXgw sc-fXeWAj fOpFmZ">
                                    <img src="./logoconfiauto.png" alt="Logo" style={{ width: '115px', position: 'absolute', margin: 0, top: '-20px', left: '19px'}}/>
                                </Link>
                                <div className="sc-fXEqDS ibDPcO">
                                    {pages.map((page,i) => (
                                        <Link key={i} to={page.src} onClick={handleCloseUserMenu} className="sc-dvQaRk jcGXgw sc-eFegNN ifcjbQ">
                                            <span className="sc-jWUzzU jXgGHC">{page.title}</span>
                                        </Link>
                                    ))}
                                    { token ? (
                                        <>
                                            <Link  to="/dashboard" className="sc-dvQaRk jcGXgw sc-eFegNN ifcjbQ">
                                                <span className="sc-jWUzzU jXgGHC">{username}: Dashboard</span>
                                            </Link>
                                            <Link onClick={()=> handleToken('')} className="sc-dvQaRk jcGXgw sc-eFegNN ifcjbQ">
                                                <span className="sc-jWUzzU jXgGHC">Cerrar sesión</span>
                                            </Link>
                                        </>
                                    ):(
                                        <Link to='login' onClick={handleCloseUserMenu} className="sc-dvQaRk jcGXgw sc-eFegNN ifcjbQ">
                                            <span className="sc-jWUzzU jXgGHC">Iniciar sesión</span>
                                        </Link>
                                    )}
                                </div>
                            </Box>
                        </Container>
                    </AppBar>
                    <Routes>
                        <Route path="/" element={ <Inicio notification={MySwal} />} />
                        <Route path="/cars" element={ <Vehiculos notification={MySwal} />} />
                        <Route path="/car/:name" element={ <Vehiculo notification={MySwal} />} />
                        <Route path="/dashboard" element={ token ? <Dashboard notification={MySwal} /> : <Navigate to="/login" replace /> } />
                        <Route path="/terminos-y-condiones" element={ <TerminosCondiciones notification={MySwal} />} />
                        <Route path="/contacto" element={ <Contacto notification={MySwal} />} />
                        <Route path="/nosotros" element={ <Nosotros notification={MySwal} />} />
                        <Route path="/login" element={ !token ? <Login notification={MySwal} handleToken={handleToken} /> : <Navigate to="/dashboard" replace /> } />
                    </Routes>
                    
                    <footer className="footer" data-v-695d18f5="" data-v-30067be2="">
                        <div className="g-container g-container--large footer-container" data-v-695d18f5="">
                            <div className="accordion" data-v-695d18f5="">
                                <div className="accordion-item" data-v-011193f0="" data-v-695d18f5="">
                                    <div className="heading" data-v-011193f0="">
                                        <span data-v-011193f0="" id="footer-accordion-1-button" aria-expanded="false" aria-controls="footer-accordion-1" tabindex="0" type="button" className="accordion-button">
                                            ConfiautoCLN
                                        </span>
                                    </div>
                                    <div role="region" className="accordion-content" data-v-011193f0=""  aria-labelledby="footer-accordion-1-button">
                                        <div className="accordion-content-wrap" data-v-011193f0="">
                                            <ul className="accordion-list" data-v-695d18f5="">
                                                <li data-v-695d18f5="">
                                                    <Link to="/cars">
                                                        Comprar
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item" data-v-011193f0="" data-v-695d18f5="">
                                    <div className="heading" data-v-011193f0="">
                                        <span data-v-011193f0="" id="footer-accordion-2-button" aria-expanded="false" aria-controls="footer-accordion-2" tabindex="0" type="button" className="accordion-button">
                                            Nosotros
                                        </span>
                                    </div>
                                    <div role="region" className="accordion-content" data-v-011193f0=""  aria-labelledby="footer-accordion-2-button">
                                        <div className="accordion-content-wrap" data-v-011193f0="">
                                            <ul className="accordion-list" data-v-695d18f5="">
                                                <li data-v-695d18f5="">
                                                    <Link to="/nosotros" data-v-695d18f5="" >
                                                        Como trabajamos
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item" data-v-011193f0="" data-v-695d18f5="">
                                    <div className="heading" data-v-011193f0="">
                                        <span data-v-011193f0="" id="footer-accordion-3-button" aria-expanded="false" aria-controls="footer-accordion-3" tabindex="0" type="button" className="accordion-button">
                                            Contacto
                                        </span>
                                    </div>
                                    <div role="region" className="accordion-content" data-v-011193f0=""  aria-labelledby="footer-accordion-3-button">
                                    <div className="accordion-content-wrap" data-v-011193f0="">
                                        <ul className="accordion-list" data-v-695d18f5="">
                                            <li data-v-695d18f5="">
                                                <a href="tel:6675445969" data-v-695d18f5="">
                                                    (667) 544 5969
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item" data-v-011193f0="" data-v-695d18f5="">
                                    <div className="heading" data-v-011193f0="">
                                        <span data-v-011193f0="" id="footer-accordion-4-button" aria-expanded="false" aria-controls="footer-accordion-4" tabindex="0" type="button" className="accordion-button">
                                            Empresa
                                        </span>
                                    </div>
                                    <div role="region" className="accordion-content" data-v-011193f0=""  aria-labelledby="footer-accordion-4-button">
                                        <div className="accordion-content-wrap" data-v-011193f0="">
                                            <ul className="accordion-list" data-v-695d18f5="">
                                                <li data-v-695d18f5="">
                                                    <Link to="/terminos-y-condiones" data-v-695d18f5="" >
                                                        Terminos de uso
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="left" data-v-695d18f5="">
                                <a href="/" data-v-695d18f5="" data-original-href="/">
                                    <span className="u-sr" data-v-695d18f5="">Inicio</span> 
                                    <img src="./logoconfiauto.png" alt="Logo" className="logo" data-v-695d18f5="" />
                                </a>
                                <ul className="social-list" data-v-695d18f5="">
                                    <li data-v-695d18f5="">
                                        <a href="https://www.instagram.com/confiauto_cln"  data-v-695d18f5="" target="_blank" rel="noopener">
                                            <InstagramIcon />
                                        </a>
                                    </li>
                                    <li data-v-695d18f5="">
                                        <a href="https://www.facebook.com/profile.php?id=100087758733827"  data-v-695d18f5="" target="_blank" rel="noopener">
                                            <FacebookIcon />
                                        </a>
                                    </li>
                                </ul>
                                <p className="copyright" data-v-695d18f5="">
                                    © 2023 CONFIAUTO CULIACAN. TODOS LOS DERECHOS RESERVADOS.
                                </p>
                            </div>
                        </div>
                    </footer>
                </div>
            </Container>
            { anchorElNav && (
                <div className="sc-gyElHZ hSEEkc">
                    <div className="sc-gjNHFA eEoXnP header-mobile-drawer-overlay-appear-done header-mobile-drawer-overlay-enter-done" onClick={handleCloseNavMenu}>
                    </div>
                    <div className="sc-fmciRz bnrHuJ header-mobile-drawer-content-appear-done header-mobile-drawer-content-enter-done">
                        <div className="sc-gslxeA kPMUQh">
                            <Link to="/cars" onClick={handleCloseNavMenu} className="sc-dvQaRk jcGXgw sc-avest jQOhsI">
                                <span className="sc-iCfMLu sc-cvZCdy bKbpOj dRvMaC">Vehículos</span>
                            </Link>
                            <Link to="/nosotros" onClick={handleCloseNavMenu} className="sc-dvQaRk jcGXgw sc-avest jQOhsI">
                                <span className="sc-iCfMLu sc-cvZCdy bKbpOj dRvMaC">Nosotros</span>
                            </Link>
                            <Link to="/contacto" onClick={handleCloseNavMenu} className="sc-dvQaRk jcGXgw sc-avest jQOhsI">
                                <span className="sc-iCfMLu sc-cvZCdy bKbpOj dRvMaC">Contacto</span>
                            </Link>
                            { token ? (
                                <>
                                    <div className="sc-eXlEPa iXKoRS">
                                        <button data-qa="Admin" aria-expanded="false" className="sc-ieecCq liZQLK sc-iFMAIt eiiDgB">
                                            <span className="sc-iCfMLu sc-kTLmzF bKbpOj bGBrSp">{username}</span>
                                            <svg className="sc-jrQzAO hPEbqG sc-dwsnSq kXJkvK" fill="#041022" stroke="none" width="12" height="8" viewBox="0 0 12 8">
                                                <path
                                                    d="M10.7343 0L5.96825 4.70318L1.26485 0.0609197L0 1.30933L5.96825 7.2L12 1.24841L10.7343 0Z">
                                                </path>
                                            </svg>
                                        </button>
                                        <div className="sc-iqVWFU htXBdP">
                                            <Link to="/dashboard" onClick={handleCloseNavMenu} className="sc-dvQaRk jcGXgw sc-jtXEFf jCesrW">
                                                <span className="sc-furwcr sc-kiIyQV dRtLXI">Dashboard</span>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="sc-djWRfJ fbYLUi">
                                        <Link to='/login' onClick={()=> { handleToken(''); handleCloseNavMenu(); }} className="sc-gIBqdA iQdcpI">
                                            <button  className="sc-fFeiMQ hrJHT sc-iWVNaa iKvlAa">CERRAR SESIÓN</button>
                                        </Link>
                                    </div>
                                </>
                            ):(
                                <div className="sc-djWRfJ fbYLUi">
                                    <Link to='/login' onClick={handleCloseNavMenu} className="sc-gIBqdA iQdcpI">
                                        <button  className="sc-fFeiMQ hrJHT sc-iWVNaa iKvlAa">INICIAR SESIÓN</button>
                                    </Link>
                                </div>
                            )}
                        
                        </div>
                    </div>
                </div>
            )}
        </Router>
        <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<WhatsAppIcon  />}
            onClick={()=> window.open(`https://api.whatsapp.com/send?phone=526675445969`)}
        >
        </SpeedDial>
    </div>
  );
}

export default App;