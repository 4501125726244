import React, { useState, useEffect } from 'react';

import CardVehiculo from '../Vehiculos/CardVehiculo';
import DescriptionIcon from '@mui/icons-material/Description';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BadgeIcon from '@mui/icons-material/Badge';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";
import api,{ URL_API } from '../../utils/api';
import './Inicio.css';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';


import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';

export default function Inicio() {
  let navigate = useNavigate();
  const [loadingRecientes,setLoadingRecientes] = useState(true); 
  const [cars,setCars] = useState([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const loading = open && options.length === 0;

  async function getCars() {
    setLoadingRecientes(true);
    const response = await api.get('vehiculos/latest');
    setCars([
        ...response.data.map(x => ({
            ...x, 
            imagenes: [
                ...x.imagenes.map(img => ({
                  src: URL_API + img.src, 
                  titulo: x.modelo +' '+ x.marca_nombre +' '+ x.nombre, 
                  tipo: '' 
                }))
              ]
        }))
    ])
    setLoadingRecientes(false);
  }

  useEffect(() => {
    getCars();
  },[]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
        const { data: { transmisiones, colores, marcas, motores, tipos_vehiculos} } = await api.get('catalogos', { params: { busqueda: inputValue } });
        const { data } = await api.get('vehiculos/autocomplete', { params: { busqueda: inputValue } });
      if (active) {
        setOptions([
            ...transmisiones.map(x => ({...x, agrupador: 'TRANSMISIONES', agrupador_codigo: 'transmision', autocomplete: x.nombre })),
            ...colores.map(x => ({...x, agrupador: 'COLORES', agrupador_codigo: 'color', autocomplete: x.nombre })),
            ...marcas.map(x => ({...x, agrupador: 'MARCAS', agrupador_codigo: 'marca', autocomplete: x.nombre })),
            ...motores.map(x => ({...x, agrupador: 'MOTORES', agrupador_codigo: 'motor', autocomplete: x.nombre })),
            ...tipos_vehiculos.map(x => ({...x, agrupador: 'TIPOS VEHICULOS', agrupador_codigo: 'tipo_vehiculo', autocomplete: x.nombre })),
            ...data.map(x => ({...x, agrupador: 'MODELOS', agrupador_codigo: 'modelo' })),
        ]);
      }
    })();
    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  return (<>
    <div className="sc-bPVzhA iphEJt">
        <div className="images" data-v-96f9fdd4="" style={{ background: '#161616'}}>
            <Container style={{padding: 0}}>
                <Swiper 
                    style={{ height: '400px' }}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    autoHeight={false}
                    pagination={{
                        clickable: true,
                    }}
                    centeredSlides={true}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                >
                    <SwiperSlide style={{ height: '100%'}}>
                        <div className="title" data-swiper-parallax="-300" >Haz que suceda tu siguiente GRAN VIAJE</div>
                        <picture data-v-96f9fdd4="">
                            <img alt={1} data-sizes="auto"  data-v-96f9fdd4="" className='img-swipper img-desktop' src={'banner0.jpg'} />
                            <img alt={1} data-sizes="auto"  data-v-96f9fdd4="" className='img-swipper img-movil' src={'movil0.jpg'} />
                        </picture>
                    </SwiperSlide>
                    <SwiperSlide style={{ height: '100%'}}>
                        <div  className="title" data-swiper-parallax="-300" >Adquiere tu auto de manera sencilla</div>
                        <picture data-v-96f9fdd4="">
                            <img alt={1} data-sizes="auto"  data-v-96f9fdd4="" className='img-swipper img-desktop' src={'banner1.jpg'} />
                            <img alt={1} data-sizes="auto"  data-v-96f9fdd4="" className='img-swipper img-movil' src={'movil1.jpg'} />
                        </picture>
                    </SwiperSlide>
                    <SwiperSlide style={{ height: '100%'}}>
                        <div  className="title" data-swiper-parallax="-300" >Si lo sueñas, lo compras</div>
                        <picture data-v-96f9fdd4="">
                            <img alt={1} data-sizes="auto"  data-v-96f9fdd4="" className='img-swipper img-desktop' src={'banner2.jpg'} />
                            <img alt={1} data-sizes="auto"  data-v-96f9fdd4="" className='img-swipper img-movil' src={'movil2.jpg'} />
                        </picture>
                    </SwiperSlide>
                    <SwiperSlide style={{ height: '100%'}}>
                        <div  className="title" data-swiper-parallax="-300" >¿Listo para tu próximo auto?</div>
                        <picture data-v-96f9fdd4="">
                            <img alt={1} data-sizes="auto"  data-v-96f9fdd4="" className='img-swipper img-desktop' src={'banner3.jpg'} />
                            <img alt={1} data-sizes="auto"  data-v-96f9fdd4="" className='img-swipper img-movil' src={'movil3.jpg '} />
                        </picture>
                    </SwiperSlide>
                </Swiper>           
            </Container>
        </div>
    </div>
    <div data-element="Container"  className="sc-kBysnm fGZseK">
        <div data-element="ContentContainer"  className="sc-fjHZBf iTxJbP">
            <h2 data-element="Title" className="sc-gsDKAQ sc-jRyozQ gsoPfm kHZKIa">Busca un vehículo</h2>
        </div>
        <br />
        <div data-element="InputContainer"  className="sc-kmiuhe jdvLix">
            <div className="jss10 jss11" data-element="LicensePlateContainer" style={{width: '100%'}}>
                <div data-element="SearchContainer"  className="sc-jVslSq jxjSHQ" style={{width: '100%'}}>
                    <div data-element="HeroAutoCompleteBox" className="sc-jWWnA cxksTV">
                        <div data-element="AutocompleteContainer"  className="sc-bgtQTB gNSbCH">
                            <Autocomplete
                                className='jss8 jss9'
                                freeSolo
                                groupBy={(option) => option.agrupador}
                                disableClearable
                                onChange={(event, value) => {
                                    let filtrosTodos = {};
                                    if(value && value.autocomplete){
                                        if(value.agrupador_codigo === 'modelo') {
                                            filtrosTodos = {
                                                modelo:  value.modelo,
                                                marca:  value.codigo_marca,
                                                anio:  value.anio +'-'+value.anio,
                                            };
                                        } else {
                                            filtrosTodos = {
                                                [value.agrupador_codigo]:  value.codigo,
                                            };
                                        }
                                    } else {
                                        filtrosTodos = {
                                            modelo:  value
                                        };
                                    }
                                    return navigate("/cars?" +  Object.entries(filtrosTodos).map(([key,value]) => key + '=' + value ).join('&'), { replace: true });
                                }}
                                onInputChange={(event, newInputValue) => {
                                  setInputValue(newInputValue);
                                }}
                                inputValue={inputValue}
                                open={open}
                                onOpen={() => {
                                  setOpen(true);
                                }}
                                onClose={() => {
                                  setOpen(false);
                                }}
                                isOptionEqualToValue={(option, value) => option.autocomplete === value.autocomplete}
                                getOptionLabel={(option) => option.autocomplete}
                                options={options}
                                loading={loading}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                              <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                              </React.Fragment>
                                            ),
                                            type: 'search',
                                            className: 'jss6',
                                            placeholder: "Buscar por modelo o marca"
                                        }}
                                        className="jss6"
                                        fullWidth
                                    />
                                )}
                            />
                        </div>
                        <button data-qa="Search Button" color="primary" data-element="SearchButton" className="sc-hBUSln bFdfPe sc-fItasp eNmirH" onClick={() => navigate("/cars?modelo="+inputValue) }>Buscar</button>
                    </div>
                    <div data-element="SubtextWrapper"  className="sc-heudyb hXPmYK">
                        <span data-element="SearchSubtext"  className="sc-iCfMLu gTDDhu">Busca el vehículo ideal para ti</span>
                    </div>
                </div>
            </div>
            <button class="sc-fFeiMQ hrJHT sc-iWVNaa" onClick={() => navigate("/cars") }>Ver más vehículos</button>
        </div>
    </div>
    <div data-v-9f4e18d2="" data-v-3c4b021b="" id="vdp-similar-listings" className="similars compare-enabled">
        <div data-v-9f4e18d2="" className="g-container">
            <h2 data-v-9f4e18d2="" id="similars-title" className="sc-gsDKAQ sc-jRyozQ gsoPfm kHZKIa">
                Agregados recientemente
            </h2>
            { loadingRecientes && ( <div data-v-9f4e18d2="" className="g-loader" ><span data-v-9f4e18d2="" className="u-sr">loading ...</span></div> )}
            <div data-v-9f4e18d2="" aria-labelledby="tab-recommended" role="tabpanel" className="similars-wrap" >
                { cars.map((car,i)=> <CardVehiculo key={i} car={car} />) }
            </div>
        </div>
    </div>
    <div className="MuiContainer-root jss1 jss66 default-padding-top-and-bottom jss64 jss65 MuiContainer-maxWidthLg">
        <h2 data-v-9f4e18d2="" id="similars-title" className="sc-gsDKAQ sc-jRyozQ gsoPfm kHZKIa">
            Valores a nuestros clientes
        </h2>
        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2" data-element="Grid">
            <div className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6">
                <div data-element="Value" className="sc-eAcRFu eYwlPv">
                    <span data-element="StyledTitle" className="sc-gKclnd sc-iztaBw dyesrI ctDlzA">PRECIO</span>
                    <span data-element="unknown" className="sc-iCfMLu gTDDhu">Hemos hecho que todo el proceso de compra de automóviles sea más eficiente</span>
                </div>
            </div>
            <div className="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-6">
                <div data-element="Value" className="sc-eAcRFu eYwlPv">
                    <span data-element="StyledTitle" className="sc-gKclnd sc-iztaBw dyesrI ctDlzA">MÚLTIPLES INSPECCIONES</span>
                    <span data-element="unknown" className="sc-iCfMLu gTDDhu">Nuestros autos son inspeccionados por problemas de seguridad, mecánicos y estéticos.</span>
                </div>
            </div>
        </div>
    </div>
    <div data-v-9f4e18d2="" data-v-3c4b021b=""  className="similars compare-enabled">
        <div data-v-9f4e18d2="" className="g-container">
            <h2 data-v-9f4e18d2="" id="similars-title" className="sc-gsDKAQ sc-jRyozQ gsoPfm kHZKIa">
                ¿Cómo puedo obtener mi crédito en Confiauto CLN?
            </h2>
            <div className="View__Grid-sc-qb2sjr-4 dylusB">
                <img src="credito.webp" alt="Vehicle Service Protection Car" className="View__Image-sc-qb2sjr-11 cXQgdm" />
                <div className="View__Benefits-sc-qb2sjr-5 IzITK">
                    <span className="sc-gKclnd View__TitleThree-sc-qb2sjr-6 dyesrI kPywxM" style={{fontSize: '28px'}}><BadgeIcon style={{fontSize: '20px'}}/> Identificación oficial vigente</span>
                    <div className="View__Divider-sc-qb2sjr-7 kULphU"></div>
                    <span className="sc-gKclnd View__TitleThree-sc-qb2sjr-6 dyesrI kPywxM" style={{fontSize: '28px'}}><DescriptionIcon style={{fontSize: '20px'}}/> Comprobante de domicilio</span>
                    <div className="View__Divider-sc-qb2sjr-7 kULphU"></div>
                    <span className="sc-gKclnd View__TitleThree-sc-qb2sjr-6 dyesrI kPywxM" style={{fontSize: '28px'}}><ReceiptIcon style={{fontSize: '20px'}}/> Comprobante de ingresos</span>
                    <div className="View__Divider-sc-qb2sjr-7 kULphU"></div>
                </div>
            </div>
        </div>
    </div>
    <div data-element="Container"  className="sc-bOTDDd dMWZGI">
        <div data-element="Section"  className="sc-hrJsxi gdDrAZ">
            <h2 data-v-9f4e18d2="" id="similars-title" className="sc-gsDKAQ sc-jRyozQ gsoPfm kHZKIa">
                Marcas populares
            </h2>
            <div data-element="BrandImageContainer"  className="sc-hRnpUl jWIpml">
                <div  className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/jeep-logo.svg" alt="Jeep logo" loading="lazy" className="sc-epFoly ftGILD" />
                    </div>
                </div>
                <div  className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/toyota-logo.svg" alt="Toyota logo" loading="lazy" className="sc-epFoly fmFwMV" />
                    </div>
                </div>
                <div className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/mercedes-benz-logo.svg" alt="Mercedes Benz logo" loading="lazy" className="sc-epFoly brIhhI" />
                    </div>
                </div>
                <div  className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/audi-logo.svg" alt="Audi logo" loading="lazy" className="sc-epFoly ctJrjn" />
                    </div>
                </div>
                <div  className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/bmw-logo.svg" alt="BMW logo" loading="lazy" className="sc-epFoly cByaVA" />
                    </div>
                </div>
                <div className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/honda-logo.svg" alt="Honda logo" loading="lazy" className="sc-epFoly dxaCvv" />
                    </div>
                </div>
                <div  className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/chevrolet.svg" alt="Chevrolet logo" loading="lazy" className="sc-epFoly ftGILD" />
                    </div>
                </div>
                <div  className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/dodge.svg" alt="Dodge logo" loading="lazy" className="sc-epFoly fmFwMV" />
                    </div>
                </div>
                <div className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/nissan.svg" alt="Nissan logo" loading="lazy" className="sc-epFoly brIhhI" />
                    </div>
                </div>
                <div  className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/susuki.svg" alt="Susuki logo" loading="lazy" className="sc-epFoly ctJrjn" />
                    </div>
                </div>
                <div  className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/vols.svg" alt="Vols logo" loading="lazy" className="sc-epFoly cByaVA" />
                    </div>
                </div>
                <div className="sc-fWCJzd dpogyX sc-hCwLRM dMzqbu">
                    <div className="sc-eEvSnX fcYBqO">
                        <img src="./companias/ford.svg" alt="Fornd logo" loading="lazy" className="sc-epFoly dxaCvv" />
                    </div>
                </div>
            </div>
        </div>
       
    </div>
  </>);
}