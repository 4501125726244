import React from 'react';
import './Dashboard.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/lazy";
import "swiper/css/pagination";
import { Navigation, Pagination, Lazy } from "swiper";
import { formatCurrency } from '../../utils/utils';
import { Link } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import api, { URL_API } from '../../utils/api';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import EditarVehiculo from './EditarVehiculo';
import moment from 'moment';

export default function CardVehiculo({ car, notification, reload,  marcas, transmisiones, motores, colores, tipos_vehiculos }) {
    const handleClickEliminar = async (id) => {
        const result = await notification.fire({
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonText: 'Si, confirmar',
            title: '¿Desea eliminar el vehículo?',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#941b1c',
        });
        if (result.isConfirmed) {
            const res = await api.post('vehiculos/eliminar/'+id);
            
            notification.fire({
                position: 'top-end',
                icon: 'success',
                title: '¡Vehículo eliminado con exito!',
                showConfirmButton: false,
                timer: 1500
            });
            reload();
        } 
    };
    
    const handleClickCambiarEstatus = async (id, activo) => {
        const result = await notification.fire({
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonText: 'Si, confirmar',
            title: '¿Desea cambiar el estatus del vehículo?',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#941b1c',
        });
        if (result.isConfirmed) {
            const res = await api.post('vehiculos/estatus/'+ id +'/'+ (activo ?  1: 0));
            notification.fire({
                position: 'top-end',
                icon: 'success',
                title: '¡Vehículo actualizado con exito!',
                showConfirmButton: false,
                timer: 1500
            });
            reload();
        }
    };
    return (
        <article className="wrap" data-v-96f9fdd4="" data-v-8de4a01c="">
            <div className="favorite-wrap srp" data-v-185805e8="" data-v-96f9fdd4="">
                <button type="button" className="favorite" data-v-185805e8="" onClick={() => handleClickEliminar(car.id)}>
                    <DeleteOutlineIcon style={{ color: '#941b1c'}} />
                </button>
            </div>
            <EditarVehiculo
                marcas={marcas}
                transmisiones={transmisiones}
                motores={motores}
                tipos_vehiculos={tipos_vehiculos}
                colores={colores}
                notification={notification}
                reload={reload}
                vehiculo_id={car.id}
            />
            <div className="favorite-wrap srp" data-v-185805e8="" data-v-96f9fdd4="" style={{right: '6em'}}>
                <button type="button" className="favorite" data-v-185805e8="" onClick={() => handleClickCambiarEstatus(car.id,!car.activo)}>
                    { car.activo ? (
                        <ToggleOnIcon style={{ color: '#941b1c'}} />
                    ) : (
                        <ToggleOffIcon style={{ color: '#941b1c'}} />
                    ) }
                
                </button>
            </div>
            <Link to={'/car/' + car.kebab_name} target="_self" className="listing-link" data-v-96f9fdd4="">
                <div className="images" data-v-96f9fdd4="">
                    <Swiper 
                        navigation={true}
                        lazy={{
                            enabled: true,
                            loadPrevNext: true, 
                            loadPrevNextAmount: 2 
                        }}
                        modules={[Navigation, Pagination, Lazy]} 
                        pagination={{ clickable: true }}
                    >
                        { car.imagenes.map((image,i) => (
                          <SwiperSlide key={i}>
                            {({ isActive }) => (
                                <div className="img-wrap" data-v-96f9fdd4="">
                                    <picture data-v-96f9fdd4="">
                                        { isActive && (<img alt={image.titulo} data-sizes="auto" className="img lazyautosizes lazyloaded" data-v-96f9fdd4="" sizes="333px" src={image.src} />)}
                                    </picture>
                                </div>
                            )}
                          </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                { car.enganche > 0 && car.mensualidad > 0 && car.plazos > 0 && (
                    <div data-v-727f32ec="" style={{color: '#fff', fontSize: '1rem',  fontWeight: '800', letterSpacing: '.07rem', textTransform: 'uppercase', width: '100%', background: 'rgb(148, 27, 28)', border: 'none', textAlign: 'center', margin: 0}}>
                        <span data-v-727f32ec="">
                            {formatCurrency(car.enganche,0)} de enganche. <br /> {formatCurrency(car.mensualidad,0)} a {car.plazos} meses
                        </span>
                    </div>
                )}
                <div className="details" data-v-96f9fdd4="">
                    <div data-v-727f32ec="" className="old-price">
                        <span data-v-727f32ec="">
                            
                        </span>
                    </div>
                    <div className="details-wrap" data-v-96f9fdd4="">
                        <div data-v-96f9fdd4="">
                            <h3 className="title" data-v-96f9fdd4="">
                                {car.modelo} {car.marca_nombre} {car.nombre}
                            </h3>
                            <div data-v-96f9fdd4="">{car.edicion} / {car.kilometraje} kilómetros</div>
                        </div>
                        <div className="details-right" data-v-96f9fdd4="">
                            <div className="price" data-v-96f9fdd4="">
                                {formatCurrency(car.precio,0)}
                            </div>
                            <button aria-haspopup="dialog" role="button" aria-expanded="false" aria-controls="monthlyPrice-961346367276139923" className="est-fee" data-v-96f9fdd4="">
                                <span className="est-fee-btn" data-v-96f9fdd4="">
                                hace {moment().diff(moment(car.fecha_alta), 'days')} días
                                </span>
                            </button>
                        </div>
                    </div>
                    <ul className="jellybeans" data-v-96f9fdd4="">
                        <li className="jellybeans-item" data-v-96f9fdd4="">
                            <div aria-haspopup="dialog" role="button"  aria-expanded="false" aria-controls="badge-9167667220933256018-0" className="jellybeans-btn" data-v-96f9fdd4="" style={{ background: car.activo ? 'rgb(102, 187, 106)':  'rgb(112 107 107)' }}>
                                <span data-v-96f9fdd4="" >{car.activo ? 'Activo':'Inactivo'}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </Link>
        </article>
     );
}